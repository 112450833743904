<!--=== FOR TEST ==========-->
<!--// !!! FOR TEST Onboarding-->
<div *ngIf='!mainS.forProd' class='forTest' [matMenuTriggerFor]='menu'></div>
<mat-menu #menu='matMenu'>
  <div (click)='onboarding()'>onboarding</div>
</mat-menu>

<div #wrapperShellRef class='wrapShell g-flex-column__item'
     [class.wrapShell--mobile]='(deviceS.isMobile$ | async)'
     [class.safari-browser]='deviceS.isSafari'
     [class.mobile-app]='isRunningOnMobileApp'>
  
  <menuNavigation [openMenuEvent]="parentOpenMenuEvent" [closeMenuEvent]="parentCloseMenuEvent"></menuNavigation>  
  <div *ngIf='isRunningOnMobileApp'>
    <menuMobile (openMenuEvent)="openMenu()" 
                (closeMenuEvent)="closeMenu()">
    </menuMobile>
  </div>

  <!----------------------  CONTENT  --------------------------------------->
  <div class='contentShell'>
    <router-outlet></router-outlet>
  </div>
</div>