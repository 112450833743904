<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
              arrCurrentLink: gameS.arrCurrentLinkDrop,
              objCurrentLink: (gameS.currentLink$|async)!,
              currentLink: (gameS.currentLink$|async)?.currentLink!,
              dataTable: (dataTable$|async)!,
              arrContent: (arrContent$|async),
              loadingTable: (meTableS.loadingTable$|async)             
             } as obj' class='o-wrapPage st-pading-from-menu' wrapPage [widthPage]='widthPage' cdkScrollable dataTable>

  <div class='o-header '>
    <div class='o-header__content'>
      <div class='o-header__title'>
        <headTitle [text]="'All Transactions'" svgLeft='arrow_squareLeft&24' (svgLeftEmit)='goBack()'
          typeText='text1-blueDark'></headTitle>
        <div class="st-message st-green st-ml12">
          {{countAllTransactions}}
          <span class="hide-on-mobile">Transactions</span>
        </div>
      </div>
      <div class='o-header__filters'>
        <div class="filter-panel">
          <app-filters></app-filters>
          <meSvg class="hide-on-mobile" svgName='download_3&44' (click)=downloadExcel() [disabled]='(downloadDisabled)'
            adminPermission>
          </meSvg>
        </div>
      </div>
      <app-filtersSelected></app-filtersSelected>

      <linkPage-searchFilter [haveLinkPage]="false"></linkPage-searchFilter>


    </div>
  </div>

  <div class='o-content t-wrapperTable'>
    <div class="st-table-header hide-on-mobile">
      <div class="st-table-header__ceil" style="width:120px;">
        Game
      </div>
      <div class="st-table-header__ceil" style="width: 371px;">
        Competition
      </div>
      <div class="st-table-header__ceil" style="width: 194px;">
        Transaction Date
      </div>
      <div class="st-table-header__ceil st-center" style="width: 120px;">
        Amount
      </div>
      <div class="st-table-header__ceil st-center" style="width: 115px;">
        Status
      </div>
    </div>
    <div class='t-table-v2'>
      <ng-container *ngFor='let item of obj.arrContent; let idx=index'>
        <div class='t-table-v2__item ' (click)="viewDetailsClicked(item.gameId,false)">

          <div class='t-table-v2__item__wrapCeil item-game-number' style=" width:120px;">

            <div class="hide-on-desktop">#</div>
            <div>{{item.gameNumber}}</div>
            <meSvg *ngIf="item.transferStatus=='FAILED'" [svgName]="'alert-triangle'" class="hide-on-mobile st-ml-8">
            </meSvg>
          </div>

          <div class='t-table-v2__item__wrapCeil competitionName-transfer-cents' style="width: 371px;">
            <div class="competition-name" style="font-weight: 600;">
              <div> {{item.competitionName}}</div>
              <div class="hide-on-desktop transfer-cents"> {{item.transferTotalInCents | currencyCents}}</div>
            </div>
            <div>
              {{item.gameAgeDescription}} - {{item.competitionLevel}} - {{item.gameGender}}
            </div>

          </div>

          <div class='t-table-v2__item__wrapCeil' style="width: 194px;">
            {{item.transferCreatedAt | date: globalConst.dateTimeFormat}}
          </div>
          <div class=' hide-on-mobile t-table-v2__item__wrapCeil  st-center st-600' style="width: 120px;">
            {{item.transferTotalInCents | currencyCents}}
          </div>

          <div class='t-table-v2__item__wrapCeil  st-center' style="width: 115px;">
            <!-- <status [status]="item.transferStatus"></status> -->
             <status [status]="item.transferStatus === 'OPEN' ? 'Offline' : item.transferStatus"></status>
          </div>
          <div class='hide-on-mobile t-table-v2__item__wrapCeil  st-center' style="width: 135px;">
            <btn text='View' bcg='white' btnWidth='87' [btnHeight]="36" (emit)="viewDetailsClicked(item.gameId,true)">
            </btn>
          </div>
        </div>
      </ng-container>
      <div class="t-table-v2-tableEmpty" *ngIf="(obj?.arrContent?.length??0)==0">
        Transactions will appear here
      </div>
    </div>
  </div>
  <app-tableInfiniteLoading></app-tableInfiniteLoading>
  <paginationWithSettings *ngIf='meTableS.showPagination'></paginationWithSettings>
</div>