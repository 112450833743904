import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { IJwtTokenDecoder, MeService } from '@services/me.service';
import { banRoutes_for_GROUP_ASSIGNOR, banRoutes_for_OFFICIAL, urlDashboard } from '@app/app.module';
import { TUserRoleUpperCase } from '@models/user';

@Injectable({ providedIn: 'root' })
export class BanRouteGuard implements CanActivate {

  constructor(
    private meS: MeService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // console.log('canActivate route:', route, '   route?.queryParams:', route?.queryParams);
    // console.log('canActivate state:', state, '  state?.root?.queryParams:', state?.root?.queryParams)
    const jwtTokenDecoder: IJwtTokenDecoder | null = this.meS.decode_jwt_token();
    // !!! если перешел по ссылке для регистрации то токена ещё не существует. Поэтому нужно разрешить вход
    // !!! за допуск к роутам только авторизованым - есть другой гард - AuthGuard
    if (!jwtTokenDecoder) return true;

    // console.log('jwtTokenDecoder :', jwtTokenDecoder)
    // console.log('jwtTokenDecoder?.[role-current] :', jwtTokenDecoder?.['role-current'])
    const userRole: TUserRoleUpperCase | undefined = jwtTokenDecoder?.['role-current'];
    if (!userRole) {
      console.error('!!! BanRouteGuard userRole :', userRole);
      return true;
    }

    let thisRoutNeedBan = false;
    if (userRole === 'OFFICIAL') {  // !!! запретить для судьи страницы
      banRoutes_for_OFFICIAL.forEach(el => {
        // if (state?.url?.includes(el)) thisRoutNeedBan = true;
        if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
      });
    } else { // !!! запретить для админа страницы
      banRoutes_for_GROUP_ASSIGNOR.forEach(el => {
        // if (state?.url?.includes(el)) thisRoutNeedBan = true;
        if (state?.url?.split('?')[0]?.includes(el)) thisRoutNeedBan = true;
      });
    }
    // console.log('thisRoutNeedBan :', thisRoutNeedBan);

    if (thisRoutNeedBan) {
      console.log('BanRouteGuard thisRoutNeedBan router.navigate([urlDashboard]):');
      this.router.navigate([urlDashboard]);
      return false;
    } else {
      return true;
    }
  }

}
