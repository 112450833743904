import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISticker, TStatusSticker } from '@components/__svg_img/sticker/sticker.component';
import { IErrorResponse } from '@interceptors/main-handler-interceptor.service';
import { HttpErrorResponse } from '@angular/common/http';
import { errText500 } from '@classes/dictionary';
import { selfRequestErrText } from '@services/other.service';
import { INewRequest } from '@services/base-api';

@Injectable({ providedIn: 'root' })
export class NotificationService {
  private notificationSub$ = new BehaviorSubject<ISticker>({});
  notification$: Observable<ISticker> = this.notificationSub$.asObservable().pipe(filter((m) => !!m.message));

  private _preventSuccessNotificationByUrl: string[] = [];
  constructor() {
  }

  setPreventSuccessNotificationByUrl(url: string): void {
    this._preventSuccessNotificationByUrl.push(url);
  }

  deletePreventSuccessNotificationByUrl(url: string): void {
    this._preventSuccessNotificationByUrl.splice(this._preventSuccessNotificationByUrl.indexOf(url), 1);
  }

  checkPreventSuccessNotificationByUrl(url: string): boolean {
    return this._preventSuccessNotificationByUrl.includes(url);
  }

  passNote(message: string, settings: ISticker = {}): void {
    this.notificationSub$.next({ message, settings });
  }

  showNotification_success(res: INewRequest, url: string): void {
    if (this.checkPreventSuccessNotificationByUrl(url))
      return;
    const statusSticker: TStatusSticker = 'success'; // 200 зелёным, 202 синим, остальное красным
    let message_notification = res?.message;
    if (!message_notification) return;
    new Promise(resolve => resolve(res)).then(() => {
      this.notificationSub$.next({ status: statusSticker, message: message_notification, settings: { status: statusSticker } });
    });
  }

  showNotification_error(error: HttpErrorResponse, forTest: string): void {
    const statusSticker: TStatusSticker = error?.status == 202 ? 'blue' : 'error'; // 200 зелёным, 202 синим, остальное красным
    // if (forTest) {
    // console.log('showNotification_error :', error)
    // }
    const errorResponse: IErrorResponse = error?.error;
    let message_notification = errorResponse?.message || '';
    if (error?.status === 400 && error?.url?.includes('/officials/v1/availability')) {
      message_notification = 'We were unable to save your changes because the time format entered is not correct. Please refresh the page and try again.'; 
    } else if (error?.status && error?.status >= 500 && error?.error?.message === 'Apply limit for competition is reached') {
      message_notification = selfRequestErrText;
    } else if (error?.status && error?.status == 422) {
      message_notification = error?.error?.message ?? 'The value you entered is not valid. Please check and try again or resend the code.';
    } else {
      message_notification = errorResponse?.errorDetails || errorResponse?.message || errText500;
    }
    this.notificationSub$.next({
      status: statusSticker,
      errorResponse,
      message: message_notification,
      settings: { status: statusSticker },
    });
  }

  showNotificationForReportApprove(): void {
    new Promise(resolve => resolve(true)).then(() => {
      this.notificationSub$.next({ forReportApprove: true, message: ' ' });
    });
  }
}
