import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { HelperClass } from '@classes/Helper-Classes';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { CompetitionService } from '@app/dir_group_assignor/competitions/competition.service';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { BtnComponent } from '@components/btn/btn.component';
import { InputAddressComponent } from '@components/__drop_inputs_matSelect/input-address/input-address.component';
import { ITimezone } from '@classes/geo';
import { ILocation } from '@models/location';
import { AddressCourtNameComponent } from '@components/__drop_inputs_matSelect/address-court-name/address-court-name.component';
import { BtnAddAnotherComponent } from '@components/btn-add-another/btn-add-another.component';
import {
  CompetitionsNavigationComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/competitions-navigation/competitions-navigation.component';
import { ClassCompetition, ClassCompetitionLocation } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { ActivatedRoute } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MainService } from '@services/main.service';
import { THttpMethod } from '@services/base-api';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { OtherService } from '@services/other.service';
import {
  DeleteItemForCompetitionsComponent,
} from '@app/dir_group_assignor/competitions/helperComponentsCompetitions/delete-item-for-competitions/delete-item-for-competitions.component';
import { TFieldLoc } from '@components/__blocks/location/location.service';
import { ClassDrop } from '@components/__drop_inputs_matSelect/dropdown/dropdown';
import { CheckActiveService } from '@app/dir_group_assignor/competitions/services/checkActiveService';
import { UntilDestroy } from '@ngneat/until-destroy';
import { UtilsService } from '@services/utils.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

interface IFormCompetitionsLocations {
  arrControls?: FormArray<FormGroup<IFormItemCompetitionsLocation>>;
}

interface IFormItemCompetitionsLocation {
  competitionId: FormControl<string>;
  id: FormControl<string>;
  name: FormControl<string>;
  street: FormControl<string>;
  address: FormControl<ILocation | undefined>;
  // courtNames: FormControl<string>;
  courtNamesDrop: FormControl<Array<ClassDrop>>;
}

@UntilDestroy()
@Component({
  selector: 'competitionsLocations',
  standalone: true,
  imports: [CommonModule, FieldComponent, ReactiveFormsModule, InputCtrlComponent, SvgAndTextComponent, BtnWrapComponent, BtnComponent, InputAddressComponent, AddressCourtNameComponent, BtnAddAnotherComponent, CompetitionsNavigationComponent, MatProgressSpinnerModule, SvgComponent, DeleteItemForCompetitionsComponent],
  templateUrl: './competitions-locations.component.html',
  styleUrls: ['./competitions-locations.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompetitionsLocationsComponent extends HelperClass implements OnInit {
  form!: FormGroup<IFormCompetitionsLocations>;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public competitionS: CompetitionService,
    private route: ActivatedRoute,
    public mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
    private readonly checkActiveGames: CheckActiveService,
  ) {
    super(cd);
    // this.route.params.pipe(untilDestroyed(this)).subscribe((params) => this.competitionS.checkParams(params));
    this.createForm();
  }

  ngOnInit() {
    // this.subscribeToCompetition();
  }

  // === Address ===================
  changeAddress(idx: number, value?: ITimezone | ILocation | string, ctrlName?: TFieldLoc): void { // ctrl == 'timezone' | 'street'
    if (ctrlName == 'street') {
      const ctrlAddressValue = this.arrControls?.controls[idx]?.controls?.address?.value;
      this.arrControls?.controls[idx]?.controls?.address?.patchValue({ ...ctrlAddressValue, ...value as ILocation });
    }
    if (ctrlName == 'timezone') {
      const ctrlAddressValue = this.arrControls?.controls[idx]?.controls?.address?.value;
      this.arrControls?.controls[idx]?.controls?.address?.patchValue({ ...ctrlAddressValue, timezone: value as ITimezone });
    }
  }

  changeValLoc(idx: number, loc: ILocation): void {
    this.arrControls?.controls[idx]?.controls?.address?.patchValue(UtilsService.removeEmptyKeysFromObject(loc));
  }

  changeValCourtName(idx: number, courtNamesDrop: Array<ClassDrop>): void {
    this.arrControls?.controls[idx]?.controls?.courtNamesDrop?.reset(courtNamesDrop);
    this.arrControls?.controls[idx]?.controls?.courtNamesDrop?.setValue(courtNamesDrop);
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      arrControls: this.formBuilder.array([]),
    });
    this.competitionS.locations?.forEach((el) => {
      this.arrControls.push(this.formBuilder.group({
        id: el?.id || '',
        name: el?.name || '',
        street: el?.address?.street || '',
        courtNamesDrop: el?.courtNamesDrop || [],
        competitionId: this.competitionS.competition?.id || '',
      }));
    });
    // this.subscribeToForm();

    // !!! from setForm()
    this.competitionS.locations?.forEach((el, idxArrControls) => {
      this.arrControls.clear();
      this.competitionS.competition?.locations?.forEach((item) => this.addNew(item));
    });
    setTimeout(() => this.cd.detectChanges());
  }

  get arrControls(): FormArray<FormGroup<IFormItemCompetitionsLocation>> {
    return this.form?.controls?.arrControls!;
  }

  addNew(item?: ClassCompetitionLocation, forTest?: string): void {
    const newFormGroup: FormGroup<IFormItemCompetitionsLocation> = this.formBuilder.group({
      id: [item?.id || ''],
      competitionId: [item?.competitionId || ''],
      name: [item?.name || '', Validators.required],
      street: [item?.address?.street || '', Validators.required],
      address: [item?.address || '', Validators.required],
      // courtName: [item?.courtName || ''],
      courtNamesDrop: [item?.courtNamesDrop || ''],
    });
    this.arrControls.push(newFormGroup);
  }

  // === competitions Btns Emit =========================
  async competitionsBtnsEmit(isNext: boolean): Promise<void> {
    const competitionId = this.competitionS?.competition?.id;
    const isActiveModal = await this.checkActiveGames.checkActive(competitionId, true);
    if (isActiveModal) {
      if (!competitionId) this.methodCompetitionLocations(isNext, 'post'); // create
      if (competitionId) this.methodCompetitionLocations(isNext, 'put'); // update
    }
  }

  methodCompetitionLocations(isNext: boolean, httpMethod: THttpMethod): void {
    if (this.startRequest()) return;
    const sendObj: { id: string, locations: Array<ClassCompetitionLocation> } = {
      id: this.competitionS.competition?.id!,
      locations: this.form?.getRawValue().arrControls as Array<ClassCompetitionLocation>,
    };
    sendObj?.locations?.forEach((el) => {
      // @ts-ignore
      delete el.street;
    });
    this.competitionS.addCompetitionIdBeforeSendToServer(sendObj.locations);

    // this.apiCompetitionS.methodCompetitionLocations(sendObj, this.competitionS.competition?.id!, httpMethod).toPromise()
    this.apiCompetitionS.methodCompetitionLocations(sendObj, httpMethod).toPromise()
      .then((res?: Pick<ClassCompetition, 'locations'>) => {
        if (!res?.locations) return;
        this.competitionS.competition.locations = res.locations;
        isNext ? this.competitionS.nextStep('locations') : this.competitionS.goToDashboard();
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

}

// subscribeToCompetition(): void {
//   this.competitionS.competition$.pipe(untilDestroyed(this)).subscribe((competition) => {
//     if (!competition) return;
//     if (this.competitionS.locations?.length) this.setForm('111');
//     this.competitionS.getCompetitionLocations().toPromise().then((res?: Array<ClassCompetitionLocation>) => this.setForm('222'));
//   });
// }
// setForm(forTest: string): void {
//   this.competitionS.locations?.forEach((el, idxArrControls) => {
//     this.arrControls.clear();
//     this.competitionS.competition?.locations?.forEach((item) => this.addNew(item));
//   });
//   setTimeout(() => this.cd.detectChanges());
// }
