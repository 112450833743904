import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { ClassGame } from '@app/dir_group_assignor/games/game';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { urlGameInfo } from '@app/app.module';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';

@Component({
  selector: 'app-games-table-mobile',
  templateUrl: './games-table-mobile.component.html',
  styleUrls: ['./games-table-mobile.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    DateFormattedComponent,
    RouterLink,
    GetFirstLetterPipe,
    GetAgeGenderLevelPipe,
    GetLocNameCourtNamePipe,
    GetLocationStringPipe,
    MatTooltipModule,
    MatTooltipForLocationPipe
  ],
})
export class GamesTableMobileComponent {
  readonly urlGameInfo = urlGameInfo;

  private _data = [];
  private _header = '';
  private _type = '';

  @Input()
  get data() {
    return this._data;
  }

  set data(data: any) {
    this._data = data;
  }

  @Input()
  get header() {
    return this._header;
  }

  set header(header: any) {
    this._header = header;
  }

  @Input()
  get type() {
    return this._type;
  }

  set type(type: any) {
    this._type = type;
  }

  @Output() status: EventEmitter<{ event: Event, game: ClassGame, status: string; }> = new EventEmitter();

  emitSetStatus(event: Event, game: ClassGame, status: string) {
    event.stopPropagation();
    this.status.emit({ event, game, status });
  }
}
