import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest } from '@models/response-and-request';
import { MeService } from '@services/me.service';
import { CustomDatesService } from '@classes/CustomDates';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { DeviceService } from '@services/device.service';
import { attentions } from '@app/dir_group_assignor/dashboard/dashboard';
import { OtherService } from '@services/other.service';
import { AccountApiService } from '@services/account.api.service';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { arrStatusGameString, ClassGame } from '@app/dir_group_assignor/games/game';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { GetGreetPipe } from '@app/dir_group_assignor/dashboard/pipes/get-greet.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { DateFormattedComponent } from '@components/__date/date-formatted/date-formatted.component';
import { GetAgeGenderLevelPipe } from '@pipes/get-age-gender-level.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTooltipForLocationPipe } from '@pipes/location/mat-tooltip-for-location.pipe';
import { GetLocNameCourtNamePipe } from '@pipes/location/get-loc-name-court-name.pipe';
import { GetLocationStringPipe } from '@pipes/location/get-location-string.pipe';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { GamesTableMobileComponent } from '@app/dir_group_assignor/dashboard/components/games-table-mobile/games-table-mobile.component';
import {
  TSelectedTable_dashboard_forOfficial,
  UnacceptedGamesComponent,
} from '@app/dir_group_assignor/dashboard/components/games-table/unaccepted-games/unaccepted-games.component';
import { GetChxSvgNamePipe } from '@components/__svg_img/svg/pipes/get-chx-svg-name.pipe';
import { GetSvgNameForSortPipe } from '@components/__svg_img/svg/pipes/get-svg-name-for-sort.pipe';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { NumberFormatPipe } from '@pipes/number-format.pipe';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { GreetComponent } from '@app/dir_group_assignor/dashboard/components/greet/greet.component';
import { ForTestService } from '@classes/forTest';
import { HammerModule } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { UtilsService } from '@services/utils.service';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { GetTableEmptyForDashboardPipe } from '@components/_table/pipes/get-table-empty-for-dashboard.pipe';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';

// type TGame = 'acceptedGames' | 'unacceptedGames' | 'unacceptedselAssignedGames'; // Грант
// type TSelectedBlockGamesForDashboard = 'accepted' | 'unaccepted' | 'unacceptedselfassigned'
interface ISelectedBlockGamesForDashboard {
  title: string,
  value: string | number,
  id: TSelectedTable_dashboard_forOfficial;
}

export interface IFormDashboard {
  currentViewDropdown: FormControl<string>; // andrei ===
  gameStatus: FormControl<string>;
  selfAssigned?: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-dashboardOfficial',
  standalone: true,
  imports: [CommonModule, HammerModule, ReactiveFormsModule, FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, GetGreetPipe, SvgComponent, BtnComponent, DateFormattedComponent, GetAgeGenderLevelPipe, MatTooltipModule, MatTooltipForLocationPipe, GetLocNameCourtNamePipe, GetLocationStringPipe, PhotosComponent, GamesTableMobileComponent, UnacceptedGamesComponent, GetChxSvgNamePipe, GetSvgNameForSortPipe, NothingListComponent, RouterLink, SpinnerComponent, NumberFormatPipe, WrapPageDirective, GreetComponent, LinkPageSearchFilterComponent, GetTableEmptyPipe, GetTableEmptyForDashboardPipe],
  templateUrl: './dashboard-official.component.html',
  styleUrls: ['./dashboard-official.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService], // for-filters=== for-table=== for-settings===
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardOfficialComponent extends HelperClass implements IForClassForFilters, OnInit {
  form!: FormGroup<IFormDashboard>;

  // gamesHeaderTitles = gamesHeaderTitles;
  attentions = attentions;
  displayAttention = attentions[0];

  selectedTable: TSelectedTable_dashboard_forOfficial = 'accepted';

  balance = 0.00;

  arrForColumnComingSoon: Array<ISelectedBlockGamesForDashboard> = [
    { title: 'Accepted Games', value: 0, id: 'accepted' },
    { title: 'Games to Accept', value: 0, id: 'unaccepted' },
    { title: 'Games Requested', value: 0, id: 'unacceptedselfassigned' },
  ];

  acceptedGames?: Array<ClassGame>; // Грант => вместо any поставить нужный тип или интерфейс
  unacceptedGames?: Array<ClassGame>;
  unacceptedselAssignedGames?: Array<ClassGame>;
  animationState: 'swipe-left' | 'swipe-right' | null = null;

  // readonly class_ngContent_btn_for_nothingList = class_ngContent_btn_for_nothingList;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    private apiCompetitionS: ApiCompetitionService,
    public meS: MeService,
    private otherS: OtherService,
    public router: Router,
    public dashboardS: DashboardService,
    private accountApiS: AccountApiService,
    public customDatesS: CustomDatesService,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    private forTestS: ForTestService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    // console.log('DashboardOfficialComponent :', this.meS.meRole, this.meS.me);
    this.createForm();
  }

  ngOnInit(): void {
    this.settings.sort = 'date,Asc';
    this.getDataForHTML();
    this.getBalance();
    this.setFilters(); // for-filters===
    // for test
    // setTimeout(() => {
    //   this.unacceptedGames = [this.forTestS.fakeGame, this.forTestS.fakeGame, this.forTestS.fakeGame]
    //   this.cd.detectChanges()
    // },3333)
  }

  getDataForHTML() {
    this.startLoading(); // !!! if (this.startLoading()) return; // !!! не надо здесь. Потому что фильтры можно быстро выбирать => поступит следующее значение не успеет загрузиться ответ от сервера
    // !!! Миша сказал отправлять все статусы кроме CANCELLED
    const gameStatuses = arrStatusGameString.filter((el) => el !== 'CANCELLED').join(',');
    const settings: ClassSettingsRequest = { ...this.settings, gameStatuses, size: 200 };
    return forkJoin(
      this.mainS.getGamesList({ params: { ...settings, assignStatuses: 'ACCEPTED' } }),
      this.mainS.getGamesList({ params: { ...settings, assignStatuses: 'UNACCEPTED', selfAssigned: false } }),
      this.mainS.getGamesList({ params: { ...settings, assignStatuses: 'UNACCEPTED', selfAssigned: true } }),
    ).pipe(
      untilDestroyed(this),
      map(([acceptedGames, unacceptedGames, acceptedSelfAssignedGames]) => {
        this.acceptedGames = acceptedGames.content;
        const acceptedCount = acceptedGames.totalElements || 0;

        this.unacceptedGames = unacceptedGames.content;
        const unacceptedCount = unacceptedGames.totalElements || 0;

        this.unacceptedselAssignedGames = acceptedSelfAssignedGames.content;
        const acceptedSelfAssignedCount = acceptedSelfAssignedGames.totalElements || 0;

        // Update the values in arrForColumnComingSoon
        const unacceptedItem = this.arrForColumnComingSoon.find(item => item.id === 'unaccepted');
        if (unacceptedItem) unacceptedItem.value = unacceptedCount;

        // Update the values in arrForColumnComingSoon
        const unacceptedSelfAssignedItem = this.arrForColumnComingSoon.find(item => item.id === 'unacceptedselfassigned');
        if (unacceptedSelfAssignedItem) unacceptedSelfAssignedItem.value = acceptedSelfAssignedCount;

        const acceptedItem = this.arrForColumnComingSoon.find(item => item.id === 'accepted');
        if (acceptedItem) acceptedItem.value = acceptedCount;
        this.cd.detectChanges();
      }),
    ).subscribe(() => this.endLoading());
  }

  private getBalance() {
    this.accountApiS.getBalance().pipe(untilDestroyed(this)).subscribe(balance => {
      this.balance = balance;
      this.cd.detectChanges();
    });
  }

  setStatus(event: { event: Event, game: ClassGame, status: string }) {
    // console.log('setStatus :', event);
    event.event.stopPropagation();
    if (event.game.id) {
      this.mainS.setOfficialStatus({ gameId: event.game.id, status: event.status }).pipe(untilDestroyed(this))
        .subscribe(() => this.getDataForHTML());
    }
  }

  selectTable(item: ISelectedBlockGamesForDashboard) {
    if (item.id === 'accepted') {
      this.form.patchValue({ selfAssigned: false });
      this.form.patchValue({ gameStatus: 'ACCEPTED' });
    }
    if (item.id === 'unaccepted') {
      this.form.patchValue({ gameStatus: 'UNACCEPTED' });
      this.form.patchValue({ selfAssigned: false });
    }
    if (item.id === 'unacceptedselfassigned') {
      this.form.patchValue({ gameStatus: 'UNACCEPTED' });
      this.form.patchValue({ selfAssigned: true });
    }
    this.selectedTable = item.id;
    this.cd.detectChanges();
  }

  routeToMyGames() {
    let queryParams: any = {};

    for (const key in this.form.value) {
      (typeof this.form.get(key)?.value) === 'object'
        ? queryParams[key] = this.form.get(key)?.value?.id
        : queryParams[key] = this.form.get(key)?.value;
    }

    let mappedParams = this.mapPropertyNames(queryParams,
      {
        'group': 'groupId',
        'competition': 'competitions',
        'gameStatus': 'assignStatuses',
      }, ['assignStatuses']);

    mappedParams = UtilsService.removeEmptyKeysFromObject(mappedParams);
    this.router.navigate(['/myGames'], { queryParams: mappedParams });
  }

  // Грант use service ============================================================
  private mapPropertyNames(originalObject: Record<string, any>, propertyMap: Record<string, string>, propertiesToUppercase: string[] = []): Record<string, any> {
    const result: Record<string, any> = {};

    for (const originalKey in originalObject) {
      if (originalObject.hasOwnProperty(originalKey) && propertyMap.hasOwnProperty(originalKey)) {
        const mappedKey = propertyMap[originalKey];
        result[mappedKey] = propertiesToUppercase.includes(mappedKey)
          ? originalObject[originalKey].toUpperCase()
          : originalObject[originalKey];
      } else {
        result[originalKey] = originalObject[originalKey];
      }
    }

    return result;
  }

  // === FORM =======================
  private createForm(): void {
    this.form = this.formBuilder.group({
      // search: [''],
      // viewDateRange: '',
      // group: [''],
      // competition: [{ value: '', disabled: !this.gameS.arrCompetition$.getValue()?.length }], // !this.ctrl?.group?.value
      currentViewDropdown: [''],
      gameStatus: 'ACCEPTED',
      selfAssigned: null,
      // from: this.customDatesS.todayMidnightFormatDate,
      // to: '',
    });
  }

  get ctrl(): IFormDashboard {
    return this.form?.controls;
  }

  get formValueAsDashboard(): IFormDashboard {
    return this.form?.value as unknown as IFormDashboard;
  }

  // === FILTERS ==========================================
  settings: ClassSettingsRequest = new ClassSettingsRequest({ size: 200, from: this.customDatesS.todayMidnightFormatDate, myGames: true }); // for-filters===
  setFilters(): void {
    const search = new ClassFilterInput({ typeFilter: 'search' });
    const dateRange = new ClassFilterDateRange({ typePeriod: 'current' });
    const groupId = new ClassFilterDrop({ typeFilter: 'groupId', arrayForDropdown: this.meS.me?.officialGroups || [] });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions', disabled: true });
    this.filtersS.setFilters([search, dateRange, groupId, competition], false, false);
  }

  emitSettingRequest(settings: ClassSettingsRequest): void {
    // console.log('emitSettingRequest :', settings);
    const isNewSelectedGroup = this.settings.groupId !== settings.groupId; // выбрана новая группа в дропдауне
    if (isNewSelectedGroup && settings.groupId) this.getArrCompetition(settings.groupId);
    if (!settings.groupId) { // если удалил группу из фильтра
      const competitions_filterDrop = new ClassFilterDrop({ // надо обнулить фильтр компетишнов и disabled
        typeFilter: 'competitions',
        arrayForDropdown: [],
        valueDrop: undefined,
        disabled: true,
      });
      this.filtersS.updateAllFilters([competitions_filterDrop]);
    }
    this.settings = { ...this.settings, ...settings };
    this.getDataForHTML();
  }

  getArrCompetition(groupId: string): void {
    this.apiCompetitionS.getArrCompetition({ params: { groupId } }).toPromise()
      .then((res) => {
        const arrCompetitions = res?.content || [];
        const competitions_filterDrop = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions || [],
          valueDrop: undefined,
          disabled: false,
        });
        this.filtersS.updateAllFilters([competitions_filterDrop]);
      })
      .catch(err => {
      });
  }

  // ===================================
  nextAttention() {
    this.displayAttention = attentions[this.displayAttention.id] || attentions[0];
  }

  viewAllAttentions() {
    this.router.navigate(['dashboard/attentions'], { state: { attentions: attentions } });
  }

  // Грант для всех методов ниже нужно использовать пайп ============================================================
  getTooltipText(type: string): string {
    switch (type) {
      case 'accepted':
        return 'Games that are assigned and accepted in order of nearest date and time.';
      case 'unaccepted':
        return 'Games to accept assigned to you by your assignor.';
      case 'unacceptedselfassigned':
        return 'Games you had requested and pending response by your assignor.';
      default:
        return '';
    }
  }

  viewAllText(gamesCount: number) {
    const count = (gamesCount && gamesCount) > 5 ? ('(' + gamesCount + ')') : '';
    return `View All ${count}`;
  }

  getTitleById(id: string): string {
    const item = this.arrForColumnComingSoon.find(item => item.id === id);
    return item ? item.title : '';
  }

  onSwipe(event: any, currentIndex: number) { // event: HammerInput
    if (event.type === 'swipeleft') {
      const nextIndex = currentIndex === this.arrForColumnComingSoon.length - 1 ? 0 : currentIndex + 1;
      this.selectTable(this.arrForColumnComingSoon[nextIndex]);
      this.animationState = 'swipe-left';
    } else {
      const prevIndex = currentIndex === 0 ? this.arrForColumnComingSoon.length - 1 : currentIndex - 1;
      this.selectTable(this.arrForColumnComingSoon[prevIndex]);
      this.animationState = 'swipe-right';
    }

    setTimeout(() => {
      this.animationState = null; // Сбрасываем состояние анимации после завершения
    }, 300);
  }

}

