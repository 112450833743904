<div [formGroup]='form' class='o-wrapPage wrapperDashboard' wrapPage [widthPage]='1300'>

  <!--  === FILTERS & TITLE ===========================-->
  <div class='o-header o-header--noHaveToggleCurrentPast'>
    <div class='o-header__content'>
      <app-greet class='o-header__title'></app-greet>
      <div class='o-header__filters sub-assign-description'>
        <app-dropdown *ngIf='(deviceS.isDesktop$ | async) as obj' [showAlwaysPlaceholder]='true' [array]='currentUsers'
                      formControlName='currentUsers' [width]='378' [placeholder]="currentUsers[0].titleCase || ''"></app-dropdown>
        <app-filters (emit)='emitSettingRequest($event)'></app-filters>
      </div>
      <span *ngIf='(deviceS.isMobile$ | async) as obj'>Current view as <b>Group Assignor</b> for <b>{{ currentUserName }}</b></span>
      <linkPage-searchFilter [haveLinkPage]='false'></linkPage-searchFilter>
      <app-filtersSelected></app-filtersSelected>
    </div>
  </div>

  <div class='o-content'>
    <div class='overviewDashboardWrapper'>
      <app-dashboard-home-group-assignor [settings]='settings'></app-dashboard-home-group-assignor>
    </div>

    <!--  === Total Games =============-->
    <div class='wrapperTotalGamesDashboard' *ngIf='!commingSoon'>
      <div class='wrapperTotalGamesDashboard__header'>{{ tittleName }}
        <btn text='Manage All' bcg='blueDark' btnHeight='36'></btn>
      </div>
      <div class='totalGames'>
        <div class='totalGames__header'>
          <div *ngFor='let itemHeader of dashboardS.arrHeaderTotalGames; let idx = index; let last = last'
               class='totalGames__header__item item_{{idx}}'>
            <meSvg *ngIf='idx == 0' [svgName]='itemHeader.isSelect | getChxSvgName'
                   (click)='itemHeader.isSelect = !itemHeader.isSelect'></meSvg>
            {{ itemHeader.title }}
            <meSvg *ngIf='!last' [svgName]='itemHeader.sort ? "arrow_chevronBottom&20":"arrow_chevronTop&24"'></meSvg>
          </div>
        </div>

        <div *ngFor='let gameItem of dashboardS.arrTotalGames; let idx = index' class='totalGames__itemTotalGame    ======arrTotalGames'>
          <div class='totalGames__itemTotalGame__game item_0'>
            <meSvg [svgName]='gameItem.game.isSelect|getChxSvgName' (click)='gameItem.game.isSelect = !gameItem.game.isSelect'></meSvg>
            {{ gameItem.game.title }}
          </div>
          <div class='totalGames__itemTotalGame__date item_1'>{{ gameItem.date|date:'fullDate' }}
            <br> {{ gameItem.date|date:'shortTime' }}
          </div>
          <div class='totalGames__itemTotalGame__ageLevel item_2'>{{ gameItem.age }} <br> {{ gameItem.level }}
          </div>
          <div class='totalGames__itemTotalGame__location item_3 ======arrTotalGames&!commingSoon'>
            {{ gameItem.location }}
            <br> {{ gameItem.teams }}
          </div>
          <div class='totalGames__itemTotalGame__teams item_4'>{{ gameItem.team1 }} vs.<br> {{ gameItem.team2 }}
          </div>
          <div class='totalGames__itemTotalGame__officials item_5'><img src='assets/test/test_officials.svg' alt=''></div>
          <div class='totalGames__itemTotalGame__points item_6'>
            <meSvg svgName='circle_3points2&20' (click)='null'></meSvg>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--=== SPINNER =====================================-->
<ng-template #spinner>
  <spinner></spinner>
</ng-template>
