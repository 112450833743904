import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  NavigationSkipped,
  NavigationStart,
  ResolveEnd,
  ResolveStart,
  Router,
  Routes,
} from '@angular/router';
import { EUserRole } from '@models/user';
import { BehaviorSubject } from 'rxjs';
import { OtherService } from '@services/other.service';
import { TPath } from '@app/app.module';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface IRout {
  path: string;
  text: string;
  isLinkActive: boolean;
  isVisible: boolean; // видимость в сайдбаре
}

export function addUserRoleInRoutes(routes: Routes): Routes {
  const result: Routes = [];
  // если не добавить роль юзера, то постоянно будет редирект, который в роутах прописан и router.events в AppComponent зациклится
  routes.forEach(el => {
    // const urlIncludesGroupAssignor = el.path?.toLowerCase()?.includes(EUserRole.group_assignor);
    // const urlIncludesSubAssignor = el.path?.toLowerCase()?.includes(EUserRole.sub_assignor);
    // const urlIncludesOfficial = el.path?.toLowerCase()?.includes(EUserRole.official);
    // const urlIncludesUserRole = urlIncludesGroupAssignor || urlIncludesSubAssignor || urlIncludesOfficial;
    // console.log('urlIncludesUserRole :', urlIncludesUserRole, el.path)
    // if (urlIncludesUserRole) {
    //   console.error('-===================================================== !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! :', el)
    //   return
    // }
    // export type TUserRole = 'OFFICIAL' | 'GROUP_ASSIGNOR' | 'SUB_ASSIGNOR';
    result.push({ ...el, path: el.path });
    if (!el?.path || el?.path === '**' || el?.path == ' ') return;
    // result.push({ ...el, path: 'GROUP_ASSIGNOR/' + el.path });
    // result.push({ ...el, path: 'SUB_ASSIGNOR/' + el.path });
    // result.push({ ...el, path: 'OFFICIAL/' + el.path });

    // result.push({ ...el, path: EUserRole.GROUP_ASSIGNOR + el.path });
    result.push({ ...el, path: EUserRole.group_assignor + '/' + el.path });
    // result.push({ ...el, path: EUserRole.SUB_ASSIGNOR + el.path });
    result.push({ ...el, path: EUserRole.sub_assignor + '/' + el.path });
    // result.push({ ...el, path: EUserRole.OFFICIAL + el.path });
    result.push({ ...el, path: EUserRole.official + '/' + el.path });
  });
  // console.log('====================== :', result);
  return result;
}

export type TUrlWithoutQueryParams = TPath | `${TPath}/${string}`; // string это id // example => 'competitions/list/cf2a0321-1e7f-4fb9-959f-731998cc8db'

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class RoutesService {
  routes: Array<IRout> = [];
  previousUrl = '';

  urlOnly: string = ''; // url без роли и без QueryParams // /signup // !!! NO CORRECT. NEED REPAIR !!!
  // urlWithoutQueryParams: string = ''; // example => 'competitions/list/cf2a0321-1e7f-4fb9-959f-731998cc8db'
  urlWithoutQueryParams?: TUrlWithoutQueryParams; // example => 'competitions/list/cf2a0321-1e7f-4fb9-959f-731998cc8db'
  path?: TPath; // example => 'competitions'

  // !!! signup?role=OFFICIAL&groupId=a39041e6-5619-4b2a-bf46-e3e305bf41c3&key=123
  // !!! signupOfficial?role=OFFICIAL&groupId=44bebc93-026c-4d37-b3d3-3d3a852c82eb&key=3961e608-dd7a-4bbd-96e3-cc43c30d6675
  // queryParamsObj$ = new BehaviorSubject<IQueryParams_forRegistration | null>(null);
  roleInUrl$ = new BehaviorSubject<string>(''); // роль юзера, которая в данный момент записана в url

  constructor(
    private otherS: OtherService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    // this.subscribeToQueryParams()
    this.subscribeToRouterEvents();
  }

  navigate(path: Array<string | undefined> | string, extras?: NavigationExtras): void {
    const pathString: string = Array.isArray(path) ? path.filter(el => !!el).join('') : path;
    this.router.navigate([pathString], extras);
  }

  // reset(): void {
  //   this.urlOnly = '';
  //   this.urlWithoutQueryParams = '';
  //   // this.queryParamsObj$.next(null);
  //   this.roleInUrl$.next('');
  // }

  goBack(): void {
    this.router.navigate([this.previousUrl || '/']);
  }

  // === ROUTER EVENTS ===========================
  eventNavigation$ = this.router.events
    .pipe(
      filter((event) => {
        return event instanceof ResolveStart || event instanceof ResolveEnd
          || event instanceof NavigationSkipped || event instanceof NavigationEnd;
      }),
      untilDestroyed(this),
    );

  subscribeToRouterEvents(): void {
    this.eventNavigation$.subscribe((res) => {
      const eventNavigation = res as ResolveStart || ResolveEnd || NavigationStart || NavigationSkipped || NavigationEnd;
      this.urlWithoutQueryParams = eventNavigation.url?.split('?')[0]?.replace('/', '') as TUrlWithoutQueryParams;
      this.path = this.urlWithoutQueryParams?.split('/')[0] as TPath;
      // console.error('path :', this.path);
    });
  }
}

// !!! теряются queryParams если нажать кнопку назад. Поэтому в LocalStorage сохраняю
// !!! надо reset делать если нажал goToLogin
// !!! записать в LocalStorage queryParams
// setQueryParams_TO_localStorage_forRegistration(queryParams_forRegistration?: IQueryParams_forRegistration): void {
// if (!queryParams_forRegistration) return;
// const { role, groupId, email, key } = queryParams_forRegistration;
// if (role) localStorage.setItem('forRegistration_role', role);
// if (groupId) localStorage.setItem('forRegistration_groupId', groupId);
// if (email) localStorage.setItem('forRegistration_email', email);
// if (key) localStorage.setItem('forRegistration_key', key);
// UtilsService.setQueryParams_forRegistration(queryParams_forRegistration)
// }
// !!! достать queryParams из LocalStorage и записать в queryParamsObj$
// setQueryParams_FROM_localStorage_forRegistration(): void {
// const role = localStorage.getItem('forRegistration_role') as TUserRoleUpperCase;
// const groupId = localStorage.getItem('forRegistration_groupId');
// const competitionId = localStorage.getItem('forRegistration_competitionId');
// const email = localStorage.getItem('forRegistration_email');
// const key = localStorage.getItem('forRegistration_key');
// const existQueryParams: boolean = !!role || !!groupId || !!email || !!key;
// const queryParams_forRegistration: IQueryParams_forRegistration = {};
// if (role) queryParams_forRegistration.role = role;
// if (groupId) queryParams_forRegistration.groupId = groupId;
// if (email) queryParams_forRegistration.email = email;
// if (key) queryParams_forRegistration.key = key;
// this.queryParamsObj$.next(existQueryParams ? queryParams_forRegistration : null);
// const roleForUrl: 'signupOfficial' | 'signup' = role?.toUpperCase() == 'TUserRole' ? 'signupOfficial' : 'signup';
// console.log('setQueryParams_FROM_localStorage_forRegistration :', UtilsService.getQueryParams_forRegistration())
// this.queryParamsObj$.next(UtilsService.getQueryParams_forRegistration());
// }
// getData_fromQueryParams(): IQueryParams_forRegistration {
//   // console.log('queryParamsObj$ :', this.queryParamsObj$.getValue());
//   return this.otherS.removeEmptyKeysFromObject({
//     ...this.queryParamsObj$.getValue(),
//     // role: this.queryParamsObj$.getValue()?.role,
//     // groupId: this.queryParamsObj$.getValue()?.groupId,
//     // key: this.queryParamsObj$.getValue()?.key,
//     // email: this.queryParamsObj$.getValue()?.email,
//   });
// }
