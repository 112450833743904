<div *ngIf='{ desktop: (deviceS.isDesktop$|async),
              mobile: (deviceS.isMobile$|async),
            } as obj' class='wrapperMeProfileAccount' [formGroup]='form'>

  <div *ngIf='obj.desktop' class='headerContact'>
    <headTitle text='Account' typeText='text1-blueDark' svgLeft='arrow_squareLeft&24' (svgLeftEmit)='otherS.locationBack()'></headTitle>
  </div>

  <titleAndNavigationForProfile *ngIf='obj.mobile' [typePageProfile]='typePageProfile'></titleAndNavigationForProfile>

  <div class='wrapperMeProfileAccount__content'>
    <div class='wrapperMeProfileAccount__block'>
      <div class='contentContact' [class.settings--desktop]='obj.desktop'>
        <fieldRow text='Password' text2='Please enter your current password to change your password.'
                  [class.o-itemEditContact--border]='obj.desktop'
                  class='o-itemEditContact ' typeText='text4-blueDark'></fieldRow>

        <div class="panel-standart-mobile panel-standart-mobile--password">
          <field text='Current Password' class='current-password'>
            <inputCtrl formControlName='currentPassword' placeholder='Current Password' [isFocus]='true'
                       [viewPassword]='viewPassword' (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
          </field>
          <field text='New Password' class='o-mg-t-20'>
            <inputCtrl formControlName='password' placeholder='Create a password' [viewPassword]='viewPassword'
                       (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
          </field>
          <field text='Confirm New Password' class='o-mg-t-20'>
            <inputCtrl formControlName='confirmPassword' placeholder='Re-Enter password'
                       [viewPassword]='viewPassword' (viewPasswordEmit)='viewPassword=$event' [showErrText]='true'></inputCtrl>
          </field>
          <div class='actionMeProfileAccount o-mg-t-24'>
            <btn class="cancel" text='Cancel' bcg='white' btnHeight='40' (click)='form.reset()'></btn>
            <btn class="update-password" text='Update password' bcg='blueDark' btnHeight='40' (click)='changePass()'
                 [disabled]='(form|invalidControls:arrNameCtrlForPassword) || reqPending'></btn>
          </div>
        </div>
      </div>
      <ng-container *ngIf='obj.desktop' [ngTemplateOutlet]='actionsTemplate'></ng-container>
    </div>

    <div class='contentContact wrapperMeProfileAccount__block'>
      <fieldRow text='Timezone' text2='Please select your current time zone.'
                class='o-itemEditContact' [class.o-itemEditContact--border]='obj.desktop' typeText='text4-blueDark'></fieldRow>
      <div class="panel-standart-mobile panel-standart-mobile--time">
        <field text='Time Zone' class='merge-top-24-20'>
          <select-timezone [(ngModel)]='timezone' [ngModelOptions]='{standalone: true}' (changeVal)='timezone=$event'></select-timezone>
        </field>
        <div class='actionMeProfileAccount merge-top-24-20'>
          <btn text='Cancel' bcg='white' [btnWidth]='obj.desktop ? "79":"100%"' btnHeight='40' (click)='timezone=null'
               [disabled]='!timezone'></btn>
          <btn text='Save' bcg='blueDark' [btnWidth]='obj.desktop ? "79":"100%"' btnHeight='40' (click)='updateTimezone()'
               [disabled]='!timezone || mainS.forProd'></btn>
        </div>
      </div>
    </div>

    <ng-container *ngIf='obj.mobile' [ngTemplateOutlet]='actionsTemplate'></ng-container>
  </div>
</div>
<ng-template #actionsTemplate>
  <btnWrap *ngIf='!mainS.forProd' type='col' class='actionsTemplate'>
    <btn text='Deactivate Account' [btnHeight]='(deviceS.isDesktop$|async)?48:44' bcg='white' (click)='null'></btn>
    <btn text='Delete Account' [btnHeight]='(deviceS.isDesktop$|async)?48:44' bcg='blueDark' (click)='null'></btn>
  </btnWrap>
</ng-template>