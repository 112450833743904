import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MatMenuModule } from '@angular/material/menu';
import { NothingListComponent } from '@components/nothing-list/nothing-list.component';
import { SpinnerComponent } from '@components/spinner/spinner.component';
import { StatusComponent } from '@components/status/status.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { BlockChartComponent } from '@app/dir_group_assignor/payments/additional-components/block-chart/block-chart.component';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FiltersSelectedIconComponent } from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { LinkPageComponent } from '@components/link-page/link-page.component';
import { TooltipDefaultComponent } from '@components/__tooltip/tooltip-default/tooltip-default.component';
import { ContainerTooltipComponent } from '@components/__tooltip/container-tooltip/container-tooltip.component';
import { PaginationComponent } from '@components/pagination/pagination.component';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';

import { PaymentsBalancesComponent } from '../payments-balances.component';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ClassCompetition } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { IPaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { ClassSettingsRequest } from '@models/response-and-request';
import { filter, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { HammerModule } from '@angular/platform-browser';

@Component({
  selector: 'app-payment-balances-mobile',
  standalone: true,
  imports: [
    CommonModule, FormsModule, ReactiveFormsModule, SvgAndTextComponent, SvgComponent, BtnComponent,
    MatMenuModule, NothingListComponent, SpinnerComponent, StatusComponent, HeadTitleComponent, GetStylesPipe,
    RoundBalancePipe, BlockChartComponent, DropdownComponent, FiltersComponent, FiltersSelectedComponent,
    FiltersSelectedIconComponent, LinkPageComponent, TooltipDefaultComponent, ContainerTooltipComponent,
    PaginationComponent, GetTableEmptyPipe, WrapPageDirective, PaginationWithSettingsComponent, TableEmptyComponent,
    TableInfiniteLoadingComponent, TableHeaderComponent, HammerModule
  ],
  templateUrl: './payment-balances-mobile.component.html',
  styleUrls: ['./payment-balances-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentBalancesMobileComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sentinel', { static: false }) sentinel!: ElementRef;

  tiles = [
    { title: 'Notch Pay Balance' },
    { title: 'Pending Inflows' },
    { title: 'Pending Outflows' }
  ];

  selectedTile = 0;
  currentMobileLink = { titleCase: 'All Competitions', upperCase: 'all' };
  currentLink = { titleCase: 'Balances', upperCase: 'balances' };
  allMethodsSub: Array<IPaymentMethod> | null = null;
  isFundsEnabled: boolean = false;

  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    public payS: PaymentService,
    public paymentsBalancesComponent: PaymentsBalancesComponent,
    private cd: ChangeDetectorRef
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.payS['_settings'].getValue()!.competitions = 'all';
      this.onCompetitionChange({ id: 'all' } as ClassCompetition);
    });
  }

  ngOnInit() {
    this.initializeSubscriptions();
    this.paymentsBalancesComponent.ngOnInit();
  }

  ngAfterViewInit() {
    this.paymentsBalancesComponent.ngAfterViewInit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initializeSubscriptions() {
    this.subscription.add(
      this.payS.allMethodsSub$.subscribe(value => {
        this.allMethodsSub = value;
       // console.log('allMethodsSub in Mobile:', value);
        this.checkIsFundsEnabled();
        this.cd.detectChanges();
      })
    );

    this.subscription.add(
      this.payS.isFundsEnabled$.subscribe(isEnabled => {
        this.isFundsEnabled = isEnabled;
        this.cd.detectChanges();
      })
    );

    this.subscription.add(
      this.payS.settings$.subscribe(settings => {
        if (settings) {
          this.updateIsFundsEnabled(settings);
          this.cd.detectChanges();
        }
      })
    );

    this.subscription.add(
      this.payS.competitionChanged.subscribe((selectedCompetition: ClassCompetition) => {
        this.onCompetitionChange(selectedCompetition);
        this.cd.detectChanges();
      })
    );
  }

  private updateIsFundsEnabled(settings: ClassSettingsRequest) {
    this.isFundsEnabled = this.payS.isAddFundsEnabled(settings);
  }

  private checkIsFundsEnabled() {
    const settings = this.settings;
    if (settings) {
      this.isFundsEnabled = this.payS.isAddFundsEnabled(settings);
    }
  }

  get settings() {
    return this.payS['_settings'].getValue();
  }

  onCompetitionChange(selectedCompetition: ClassCompetition) {
    const settings = this.settings;
    if (settings) {
      settings.competitions = selectedCompetition.id;
      this.updateIsFundsEnabled(settings);
    }
  }

  isAddFundsEnabled() {
    const settings = this.settings;
    if (settings) {
      return this.payS.isAddFundsEnabled(settings);
    }
    return false;
  }

  // Additional methods
  getCurrentProjectedOutgoingsPeriod() {
    return this.paymentsBalancesComponent.getCurrentProjectedOutgoingsPeriod();
  }

  toggleProjectedOutgoingsPeriod(direction: 'left' | 'right') {
    this.paymentsBalancesComponent.toggleProjectedOutgoingsPeriod(direction);
  }

  goToBalancesPay() {
    this.paymentsBalancesComponent.goToBalancesPay();
  }

  getDataForHTML() {
    this.paymentsBalancesComponent.getDataForHTML();
  }

  selectTile(index: number) {
    this.selectedTile = index;
  }

  get competitionsArray(): ClassCompetition[] {
    return this.payS.arrCompetition$.getValue() || [];
  }

  subscribeToArrCompetitions() {
    this.paymentsBalancesComponent.subscribeToArrCompetitions();
  }

 // animationState: 'swipe-left' | 'swipe-right' | null = null;
  onSwipe(event: any, currentIndex: number) {
    if (event.type === 'swipeleft') {
      // Move to the next tile, or wrap around to the first tile
      const nextIndex = currentIndex === this.tiles.length - 1 ? 0 : currentIndex + 1;
      this.selectTile(nextIndex);
     // this.animationState = 'swipe-left';
    } else if (event.type === 'swiperight') {
      // Move to the previous tile, or wrap around to the last tile
      const prevIndex = currentIndex === 0 ? this.tiles.length - 1 : currentIndex - 1;
      this.selectTile(prevIndex);
   //   this.animationState = 'swipe-right';
    }
  
    // Reset the animation state after the animation completes
    // setTimeout(() => {
    //   this.animationState = null;
    // }, 300);
  }
  
  

}
