import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TColor } from '@models/ICssStyles';
import { ILocation } from '@models/location';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { FormsModule } from '@angular/forms';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { BtnComponent } from '@components/btn/btn.component';
import { ExcludeArrOfArrPipe } from '@pipes/array/exclude-arr-of-arr.pipe';
import { OtherService } from '@services/other.service';
import { ClassDrop, getArrayDropFromString } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

@Component({
  selector: 'addressCourtName',
  standalone: true,
  imports: [CommonModule, SvgAndTextComponent, InputCtrlComponent, FormsModule, SvgComponent, BtnComponent, ExcludeArrOfArrPipe],
  templateUrl: './address-court-name.component.html',
  styleUrls: ['./address-court-name.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCourtNameComponent implements OnChanges {
  @ViewChild('inputRef') inputRef?: ElementRef;
  @Input() bcg: TColor | 'transparent' = 'white'; // background // TColor = 'blueDark' | 'grey' | 'grey_1' | 'white' | 'blue' | 'green' | 'red';

  // @Input() courtName?: string;
  courtName?: string; // сделал пока так, чтобы старый функционал strToArray() не ломать. Потом изменить надо
  @Input() courtNamesDrop?: Array<ClassDrop>;
  @Input() location?: ILocation;

  @Input() allowNew = true; // разрешить добавлять/вписывать новые элементы для courtName

  @Input() placeholder: string = 'Add multiple field or court names by pressing the “tab” key…';

  arrCourtName: Array<Array<string>> = [];
  counterForArrCourt = 0;

  @Output() changeValLoc = new EventEmitter<ILocation>();
  // @Output() changeValCourtName = new EventEmitter<string>();
  @Output() changeCourtNamesDrop = new EventEmitter<Array<ClassDrop>>();

  inputValue = '';

  constructor(
    private otherS: OtherService,
    private cd: ChangeDetectorRef,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (this.courtName) {
    //   this.strToArray(this.courtName, 'ngOnChanges');
    // }
    if (this.courtNamesDrop) {
      this.courtName = this.courtNamesDrop.map(el => el.titleCase)?.join(',');
      this.strToArray(this.courtName, 'ngOnChanges');
    }
  }

  forFocusInput(): void {
    this.inputRef?.nativeElement?.focus();
  }

  strToArray(str: string, forTest: string): void {
    this.arrCourtName = [];
    this.counterForArrCourt += 1;
    const tempArr = str.split(',')?.filter(Boolean)?.map((el, idx, arrSelf) => {
      this.counterForArrCourt += 1;
      return [`item_${this.counterForArrCourt}`, el?.trim()];
    });
    if (!this.checkIncludesElem(str, 'strToArray  ' + forTest)) this.arrCourtName.push(...tempArr);
    this.arrCourtName = this.arrCourtName?.filter(Boolean).filter((el) => el[0]?.includes('item_')); // Миша сказал адрес не нужно показывать. Только courtName
    this.inputValue = '';
    this.cd.detectChanges();
  }

  checkIncludesElem(str: string, forTest?: string): boolean { // если есть уже то вернется true // str == aaaaaaa,bbbbb,ccc
    let result = false;
    this.arrCourtName.forEach(el => {
      if (str.split(',').includes(el[1])) result = true;
    });
    return result;
  }

  keydownNewItemCourtName(event?: any) {
    if (!this.inputValue?.trim()) return;
    if (event.keyCode == 13 || event.keyCode == 9) {
      if (!this.checkIncludesElem(this.inputValue, 'keydownNewItemCourtName go to checkIncludesElem')) {
        this.courtName = `${this.courtName||""},${this.inputValue}`;
      }
      const newStr = this.courtName + ',' + this.inputValue;
      this.strToArray(newStr, 'keydownNewItemCourtName go to strToArray');
      this.forEmit();
      event.preventDefault();
    }
  }

  input(event: any): void {
    const value = event?.target?.value;
    if (value[0] === ' ' && this.inputRef?.nativeElement) this.inputRef.nativeElement.value = ''; // запрет пробелов в начале строки
  }

  removeItem(item: Array<string>, idx: number): void { // item == ['item_6', 'dfdfg']
    this.arrCourtName = this.arrCourtName?.filter((el, i) => idx !== i)?.filter(el => !!el[1] && el[1] != ' ');
    if (item[0]?.includes('item_')) {
      this.courtName = this.courtName?.replace(item[1], '');
      this.courtName = this.courtName?.split(',')?.filter(el => el && el != ' ')?.join(',');
    } else {
      // @ts-ignore
      if (this.location) this.location[item[0]] = '';
    }
    this.forEmit();
  }

  forEmit(): void {
    if (this.courtName?.startsWith(',')) this.courtName = this.courtName?.replace(',', '');
    // this.changeValCourtName.emit(this.courtName);
    const arr = this.courtName?.split(',')?.filter(Boolean);
    const result: Array<ClassDrop> = arr?.length ? getArrayDropFromString(arr, 'AddressCourtNameComponent forEmit') : [];
    this.changeCourtNamesDrop.emit(result);
  }

}
