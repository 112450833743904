import { Injectable } from '@angular/core';
import { MainService } from '@services/main.service';
import { BehaviorSubject } from 'rxjs';
import { OtherService } from '@services/other.service';
import { IPaymentMethod, ISendObjCreatePaymentMethod } from '@app/dir_group_assignor/payments/modelsForPayment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

declare var Plaid: any; // открывается в попап окне библиотека Plaid (добавление банка для оплаты)

interface IResPlaid { // response Plaid?.create(configsPlaid)?.open()
  account?: {
    class_type?: any; // null;
    id?: string; // 'EmxjazroVEHg6lqM65WAFX1yDE3wVrt9ABwNv'
    mask?: string; // '1111'
    name?: string; // 'Plaid Saving'
    subtype?: string; // 'savings'
    type?: string; // 'depository'
    verification_status?: any; // null;
  };
  account_id?: string; // 'EmxjazroVEHg6lqM65WAFX1yDE3wVrt9ABwNv'
  accounts?: Array<any>; // здесь массив объектов IResPlaid.account
  institution?: {
    institution_id?: string; // 'ins_127989'
    name?: string; // 'Bank of America'
  };
  link_session_id?: string; // '0f7d2830-b914-4193-b1ca-90b9dd689899'
  public_token?: string; // 'public-sandbox-f58b0893-aa86-406c-9fd4-0cb237b90c42'
  status?: any; // null;
  transfer_status?: any; // null;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class PlaidService {
  resPlaid: IResPlaid | null = null; // для библиотеки Plaid
  plaidAccount_id?: string; // this.resPlaid?.account_id || this.resPlaid?.account?.id || this.resPlaid?.accounts![0]?.id
  plaidToken?: string; // получам из Plaid в onSuccess()

  private isOpenPlaidSub$ = new BehaviorSubject<boolean>(false); // попап Plaid открывается с задержкой. Поэтому чтобы ничего другого не нажал, делаю поверх блока прозрачный <div class='wrapperF__fakeWrap'></div>
  isOpenPlaid$ = this.isOpenPlaidSub$.asObservable();

  private newBankMethodFromPlaidSub$ = new BehaviorSubject<IPaymentMethod | null>(null);
  newBankMethodFromPlaid$ = this.newBankMethodFromPlaidSub$.asObservable();

  competitionId?: string;

  constructor(
    private mainS: MainService,
    private otherS: OtherService,
  ) {
  }

  reset(): void {
    this.resPlaid = null;
    this.plaidAccount_id = undefined;
    this.plaidToken = undefined;
    this.isOpenPlaidSub$.next(false);
    this.newBankMethodFromPlaidSub$.next(null);
  }

  createPaymentMethodForCompetition(): void {
    // const newPaymentMethod: IPaymentMethod = {
    //   last4: '1223', // 1223
    //   id: 'this.resPlaid?.public_token', // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
    //   bankAccountDto: { bankName: 'STRIPE TEST BANK' },
    //   isSelect: true,
    // };
    // this.newBankMethodFromPlaidSub$.next(newPaymentMethod);
    // return

    const sendObjCreatePaymentMethod: ISendObjCreatePaymentMethod = {
      plaidInfo: { plaidId: this.plaidAccount_id, bankAccountToken: this.plaidToken },
    };
    this.mainS.createPaymentMethodForCompetition(sendObjCreatePaymentMethod, this.competitionId!).pipe(untilDestroyed(this))
      .subscribe((res) => {
        // if (res) {
        // !!! пока не удалять
        // const newPaymentMethod: IPaymentMethod = {
        //   type: 'ACH',
        //   last4: this.resPlaid?.account?.mask, // 1223
        //   method: this.resPlaid?.public_token, // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
        //   name: this.resPlaid?.institution?.name, // "visa" || "STRIPE TEST BANK"
        //   fromPlaid: true, // этот метод получен из попап-Plaid
        // };
        const newPaymentMethod: IPaymentMethod = {
          last4: res?.last4 || this.resPlaid?.account?.mask, // 1223
          id: res?.id || this.resPlaid?.public_token, // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
          bankAccountDto: res?.bankAccountDto || { bankName: this.resPlaid?.institution?.name },
          isSelect: true,
        };
        this.newBankMethodFromPlaidSub$.next(newPaymentMethod);
        // }
      });
  }

  // === для библиотеки Plaid ===================
  // вызывается mainS.getTokenAndLibraryPlaidCreateBank() с бэка приходит { token: string }
  // после этого открывается в попап окне библиотека Plaid (добавление банка для оплаты), туда передаётся этот токен Plaid?.create(configsPlaid)?.open()
  // там в Plaid создается метод для оплаты,
  getTokenAndLibraryPlaidCreateBank(competitionId: string): void {
    // this.onSuccess('AAAAAAAAa', {account_id: 'DDDDDDDDD'})
    // return;
    this.competitionId = competitionId;
    this.reset();
    this.isOpenPlaidSub$.next(true);
    this.mainS.getLinkToken().toPromise()// !!! from 2 version this.mainS.getTokenAndLibraryPlaidCreateBank().toPromise()
      .then((res?: { token: string }) => {
        if (!res?.token) {
          console.error('GET /api/payments/v1/payment/plaid/token', 'err', res);
          this.isOpenPlaidSub$.next(false);
          return;
        }
        const configsPlaid = {
          token: res?.token,
          onLoad: () => this.onLoad(),
          onSuccess: (public_token: any, metadata: any) => this.onSuccess(public_token, metadata),
          onExit: async (err: any, metadata: any) => this.onExit(err, metadata),
        };
        Plaid?.create(configsPlaid)?.open();
      })
      .catch((err) => {
        console.log('catch :', err);
        this.isOpenPlaidSub$.next(false);
      });
  }

  private onSuccess(public_token: string, metadata: IResPlaid): any { // public_token == 'public-sandbox-f58b0893-aa86-406c-9fd4-0cb237b90c42'
    this.resPlaid = metadata;
    this.plaidAccount_id = metadata?.account_id || metadata?.account?.id || metadata?.accounts![0]?.id;
    this.plaidToken = public_token;
    this.isOpenPlaidSub$.next(false);
    if (!this.plaidToken || !this.plaidAccount_id) {
      this.otherS.showError('Unexpected error from "Plaid" library.');
      return;
    }
    this.createPaymentMethodForCompetition();
  }

  private onLoad(): any {
  }

  private onExit(err: any, metadata: any): void {
    console.log('onExit :', err, metadata);
    this.isOpenPlaidSub$.next(false);
    this.otherS.showNotification(false, undefined, err);
  }

  // === GETTERS =========
  get isOpenPlaid(): boolean {
    return this.isOpenPlaidSub$.getValue();
  }
}

// @Injectable({ providedIn: 'root' })
// export class PlaidService {
//   resPlaid: IResPlaid | null = null; // для библиотеки Plaid
//   plaidAccount_id?: string; // this.resPlaid?.account_id || this.resPlaid?.account?.id || this.resPlaid?.accounts![0]?.id
//   plaidToken?: string; // получам из Plaid в onSuccess()
//
//   private isOpenPlaidSub$ = new BehaviorSubject<boolean>(false); // попап Plaid открывается с задержкой. Поэтому чтобы ничего другого не нажал, делаю поверх блока прозрачный <div class='wrapperF__fakeWrap'></div>
//   isOpenPlaid$ = this.isOpenPlaidSub$.asObservable();
//
//   private newBankMethodFromPlaidSub$ = new BehaviorSubject<any | null>(null);
//   newBankMethodFromPlaid$ = this.newBankMethodFromPlaidSub$.asObservable();
//
//   constructor(
//     private mainS: MainService,
//     private otherS: OtherService,
//   ) {
//   }
//
//   reset(): void {
//     this.resPlaid = null;
//     this.plaidAccount_id = undefined;
//     this.plaidToken = undefined;
//     this.isOpenPlaidSub$.next(false);
//     this.newBankMethodFromPlaidSub$.next(null);
//   }
//
//   // === для библиотеки Plaid ===================
//   // вызывается mainS.getTokenAndLibraryPlaidCreateBank() с бэка приходит { token: string }
//   // после этого открывается в попап окне библиотека Plaid (добавление банка для оплаты), туда передаётся этот токен Plaid?.create(configsPlaid)?.open()
//   // там в Plaid создается метод для оплаты,
//   getTokenAndLibraryPlaidCreateBank(): void {
//     // const test: IPaymentMethod = {
//     //   fromPlaid: true,
//     //   last4: '0000',
//     //   method: 'public-sandbox-625929ca-a7a6-48cf-9165-b65c6236e8f4',
//     //   name: 'Bank of America',
//     //   type: 'ACH',
//     // };
//     // this.newBankMethodFromPlaidSub$.next(test);
//     // return;
//
//     // this.reset();
//     // this.isOpenPlaidSub$.next(true);
//     // this.mainS.getTokenAndLibraryPlaidCreateBank().toPromise()
//     //   .then((res?: { token: string }) => {
//     //     if (!res?.token) {
//     //       console.error('GET /api/payments/funds/linktoken','err', res);
//     //       return;
//     //     }
//     //     const configsPlaid = {
//     //       token: res?.token,
//     //       onLoad: () => this.onLoad(),
//     //       onSuccess: (public_token: any, metadata: any) => this.onSuccess(public_token, metadata),
//     //       onExit: async (err: any, metadata: any) => this.onExit(err, metadata),
//     //     };
//     //     Plaid?.create(configsPlaid)?.open();
//     //   })
//     //   .catch(() => this.isOpenPlaidSub$.next(false));
//   }
//
//   private onSuccess(public_token: string, metadata: IResPlaid): any { // public_token == 'public-sandbox-f58b0893-aa86-406c-9fd4-0cb237b90c42'
//     this.resPlaid = metadata;
//     this.plaidAccount_id = metadata?.account_id || metadata?.account?.id || metadata?.accounts![0]?.id;
//     this.plaidToken = public_token;
//     this.isOpenPlaidSub$.next(false);
//     if (!this.plaidToken || !this.plaidAccount_id) {
//       this.otherS.showError('Unexpected error from "Plaid" library.');
//       return;
//     }
//
//     this.newBankMethodFromPlaidSub$.next({
//       type: 'ACH',
//       last4: this.resPlaid?.account?.mask, // 1223
//       method: this.resPlaid?.public_token, // "pm_1L14LsBIMZTJKM1cmR0q3ViF"
//       name: this.resPlaid?.institution?.name, // "visa" || "STRIPE TEST BANK"
//       fromPlaid: true, // этот метод получен из попап-Plaid
//     });
//   }
//
//   private onLoad(): any {
//   }
//
//   private onExit(err: any, metadata: any): void {
//     this.isOpenPlaidSub$.next(false);
//     this.otherS.showNotification(false, undefined, err);
//   }
//
//   // === GETTERS =========
//   get isOpenPlaid(): boolean {
//     return this.isOpenPlaidSub$.getValue()
//   }
// }
