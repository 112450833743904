import { TPayoutFormatUpperCase } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { TypeTransfer } from '@app/dir_group_assignor/payments/modelsForPayment';
import { TReportStatus } from '@app/dir_group_assignor/reports/report';
import { HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { TypeSortTable } from '@components/sortBy/sortBy';
import { TCurrentLink } from '@classes/dictionary';
import { TypeEmitSettingRequest } from '@components/_table/meTable';
import { TCurrentLink_officials } from '@app/dir_group_assignor/officials/officials';
import { TLink_groupProfile_camelCase } from '@app/group-profile/groupProfile';
import { TUserRoleUpperCase } from '@models/user';
import { preparePropertyFromDropForSendToServer } from '@components/__drop_inputs_matSelect/dropdown/dropdown';

export interface IResponse<T> {
  content?: Array<T>;
  number?: number; // текущая страница . с нуля начинается
  page?: number; // запрашиваемая страница
  numberOfElements?: number; // сколько получил элементов
  size?: number; // сколько запросили элементов
  totalElements?: number; // сколько всего элементов на сервере
  totalPages?: number; // сколько всего страниц на сервере
  search?: string;

  empty?: boolean;
  first?: boolean;
  last?: boolean;
  pageable?: { sort?: Array<any>, offset?: number, pageNumber?: number, pageSize?: number, paged?: boolean, unpaged?: boolean; };
  sort?: Array<any>;
  statusCounts?: { SUCCEEDED: any, PENDING: any, FAILED: any };
}

export const fakeSettingsRequest: IResponse<any> = {
  content: [],
  empty: false,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 5,
  size: 5,
  totalElements: 7,
  totalPages: 2,
};

export type TKeyofSettingsRequest = keyof ClassSettingsRequest;

export type IClassDefaultSettingsRequest_games = Pick<ClassSettingsRequest, 'page' | 'size' | 'sort' | 'from' | 'to' | 'currentLink_games'>;

export class ClassSettingsRequest {
  public page?: number = 1;
  public size?: number = 25;
  search?: string;
  sort?: string | Array<TypeSortTable>; // andrei== сделать чтобы только массив был
  cascadeSortFields?: string[]; // andrei== need delete
  cascadeSortOrder?: string; // andrei== need delete
  from?: string;
  to?: string;
  currentLink_games?: TCurrentLink; // 'current' | 'past' for-settingsDynamicUrl===
  currentLink_reports?: TReportStatus; // 'DUE'|'FLAGGED'|'NEEDS_APPROVAL'|'APPROVED'|'ARCHIVED' for-settingsDynamicUrl===
  currentLink_officials?: TCurrentLink_officials; // 'ACTIVE' | 'PENDING' for-settingsDynamicUrl===
  currentLink_groupProfile?: TLink_groupProfile_camelCase; // 'info' | 'adjustmentStatuses' | 'userManagement' | 'csvImportHistory' for-settingsDynamicUrl===
  typeEmitSetting?: TypeEmitSettingRequest; // !!! сейчас работает НЕкорректно for-settingsDynamicUrl=== обязательно нужно передавать для "infiniteLoading"

  groupId?: string;
  selfAssigned?: boolean;
  // ignoreAvailability?: boolean; // 13.05.24 Тимур сказал теперь этого флага не бэке нету
  competitionId?: string;
  competitions?: string;
  seasons?: string;
  assignStatuses?: string;
  levels?: string;
  myGames?: boolean;
  distance?: number | string;
  zipcode?: string;
  payoutFormat?: TPayoutFormatUpperCase;
  games?: Array<string>;
  gameStatuses?: string; // !!! Array<TStatusGame> || 'TStatusGame,TStatusGame,TStatusGame'
  transferType?: TypeTransfer;
  status?: string; // for page Payers
  gameStatus?: string;
  // viewDateRange?: TChoosePeriod;
  statuses?: TReportStatus; // !!! for page reports

  officialList?: string; // for page officials (dropdown 'My Lists')
  officialStatus?: string; // Надо дождаться бэка. на странице officials этот дропдаун
  competitionPreferences?: string; // Надо дождаться бэка. на странице officials этот дропдаун
  location?: string; // Надо дождаться бэка. на странице officials этот дропдаун

  // ages?: TAgeForOfficials; // '<15' | '16-17' | '18-25' | '26-35' | '36-45' | '46>'
  fromAge?: string; // TAge; // ages
  toAge?: string; // TAge; // ages

  activeCompetitions?: string; // Надо дождаться бэка. на странице competitions/list этот дропдаун

  userId?: string;
  gameId?: string;

  invitationText?: string;
  emails?: Array<string>;
  userRole?: TUserRoleUpperCase;

  forTest?: string;

  constructor(settingsRequest?: ClassSettingsRequest) {
    // console.log('settingsRequest :', settingsRequest, '  size :', settingsRequest?.size, '  page :', settingsRequest?.page)
    if (settingsRequest?.page) this.page = settingsRequest?.page;
    if (settingsRequest?.size) this.size = settingsRequest?.size;
    if (settingsRequest) return { ...settingsRequest, page: this.page, size: this.size };
  }

  // !!! перед отправкой на сервер удалять НЕнужные поля из запроса
  static preparePropertyFromDropForSendToServer(obj?: ClassSettingsRequest): ClassSettingsRequest {
    if (!obj) return {};
    return preparePropertyFromDropForSendToServer(obj);
  }
}

export interface IRequestOptions {
  headers?: HttpHeaders | { [header: string]: string | string[]; };
  context?: HttpContext;
  observe?: 'body';
  params?: ClassSettingsRequest | HttpParams | { [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>; };
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
}

export interface IPageable {
  page?: number;
  size?: number;
  sort?: Array<string>;
}