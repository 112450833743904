import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainService } from '@services/main.service';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ClassPayMethodAndAmount,
  IPaymentMethod,
  IResCreatePaymentForCompetition,
  ISendObjCreatePaymentForCompetition,
  TypePay,
} from '@app/dir_group_assignor/payments/modelsForPayment';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { OtherService } from '@services/other.service';
import { FundService } from '@services/funds.service';
import { PlaidService } from '@services/plaid.service';
import { switchMap } from 'rxjs/operators';
import { DeviceService } from '@services/device.service';
import { SafePipe } from '@pipes/safe.pipe';
import {
  ToggleBankCardComponent,
} from '@app/dir_group_assignor/payments/additional-components/toggle-bank-card/toggle-bank-card.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { StripeFormComponent } from '@components/stripe-form/stripe-form.component';
import { LineComponent } from '@components/line/line.component';
import { BtnComponent } from '@components/btn/btn.component';
import { GetSelectedElemsPipe } from '@pipes/get-selected-elems.pipe';
import { PayMethodComponent } from '@app/dir_group_assignor/payments/additional-components/pay-method/pay-method.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { HelperClass } from '@classes/Helper-Classes';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PopupConfirmPaymentComponent } from '@components/__popup-windows/popup-confirm-payment/popup-confirm-payment.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';

interface IPaymentsBalancePayForm {
  competitionId: FormControl<string>;
  amount: FormControl<number>;
  processingFee: FormControl<string>;
  transactionFee: FormControl<string>;
  amountPlusFee: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'app-payments-balance-pay',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SafePipe, ToggleBankCardComponent, InputCtrlComponent, StripeFormComponent, LineComponent, BtnComponent, GetSelectedElemsPipe, PayMethodComponent, SvgAndTextComponent, PopupConfirmPaymentComponent, MatTooltipModule, SvgComponent],
  templateUrl: './payments-balance-pay.component.html',
  styleUrls: ['./payments-balance-pay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentsBalancePayComponent extends HelperClass implements OnInit {
  form!: FormGroup<IPaymentsBalancePayForm>;
  showAddNewCard = false;

  selectedMethod?: IPaymentMethod;
  showConfirmPopup: boolean = false;

  // === FROM 2 VERSION ===========================================================
  srcIFrame?: any;
  payMethodAndAmount: ClassPayMethodAndAmount = new ClassPayMethodAndAmount(); // изменять ссылку, чтобы дочерний компонент отреагировал => new ClassPayMethodAndAmount(method, amount)
  rememberBankCtrl = new FormControl(false);
  showNewCard = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    public payS: PaymentService,
    public otherS: OtherService,
    public route: ActivatedRoute,
    public router: Router,
    public fundS: FundService,
    public plaidS: PlaidService,
    public deviceS: DeviceService,
    public cd: ChangeDetectorRef,
    private dialog: MatDialog,
  ) {
    super(cd);
    // this.payS.getLinkPaymentsCamelCase(this.router.url);
    this.resetAllThisData();
    this.checkLocalStorage();
    this.fundS.setStripeFields(['cardNumber', 'cardExpiry', 'cardCvc', 'cardName', 'zip']);
    this.createForm();
    this.setCompetitionIdFromQueryParams();
  }

  ngOnInit() {
    this.plaidSubscribe();
    this.subscribeToCurrentLink();
  }

  showStripeForm: boolean = false;

  // === FORM ==================================
  createForm(): void {
    this.form = this.formBuilder.group({
      competitionId: [''],
      amount: [0, [Validators.required]],
      processingFee: [{ value: 0, disabled: true }, this.fundS.link.card ? [Validators.required] : null],
      transactionFee: [{ value: 0, disabled: true }, [Validators.required]],
      amountPlusFee: [{ value: 0, disabled: true }, [Validators.required]],
    });
  }

  get ctrl(): IPaymentsBalancePayForm {
    return this.form?.controls;
  }

  selectPayMethod(isSelect: boolean, payMethod: IPaymentMethod, typePay?: TypePay | string): void {
    if (isSelect) {
      const newMethods = this.payS.methodsSub$.getValue().map((el) => {
        if (el.id != payMethod.id) return { ...el, isSelect: false };
        return el;
      });
      this.payS.methodsSub$.next(newMethods);
      this.selectedMethod = this.payS.methodsSub$.getValue().find(el => el.isSelect);
      this.cd.detectChanges();
    }
  }

  openConfirmationPopup(paymentId: string): void {
    const isMobile = this.deviceS.isMobile;
    const dialogRef = this.dialog.open(PopupConfirmPaymentComponent, {
      disableClose: true,
      data: {
        textTitle: 'Confirm Payment',
        text: 'By clicking "Confirm" you authorize Notch to debit the bank account specified above for any amount owed for charges arising from your use of Notch Pay services to pay in and fund a competition balance, pursuant to the Notch terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to Notch with 30 (thirty) days notice.',
        showCloseButton: false,
      },
      width: isMobile ? '90vw' : 'auto',
      maxWidth: isMobile ? '90vw' : 'auto'
    });
    dialogRef.componentInstance.approve.subscribe(() => {
      this.confirmPayment(paymentId, dialogRef);
    });

    dialogRef.componentInstance.close.subscribe(() => {
      dialogRef.close();
    });
  }

  confirmPayment(paymentId: string, dialogRef: MatDialogRef<PopupConfirmPaymentComponent>): void {
    this.mainS.confirmPaymentForCompetition(paymentId).toPromise()
      .then((res) => {
        // If the confirmation is successful, navigate to the balances page
        // this.showConfirmPopup = false;
        dialogRef.close();
        this.router.navigate(['balances']);
        this.cd.detectChanges();
      })
      .catch((error) => {
        console.error('Error confirming payment:', error);
      });
  }

  createPaymentForCompetition(): void {
    // if (this.startRequest()) return;
    this.reqPending = true;
    const amount: number = +this.ctrl.amount?.value || 0;
    const processingFee: number = +this.ctrl.processingFee?.value?.replace('$', '') || 0;
    const transactionFee: number = +this.ctrl.transactionFee?.value?.replace('$', '') || 0;
    const sendObj: ISendObjCreatePaymentForCompetition = { amount, fee: { transactionFee } };
    if (this.fundS.link.card) sendObj.fee!.processingFee = processingFee;
    this.mainS.createPaymentForCompetition(sendObj, this.ctrl.competitionId?.value!, this.selectedMethod?.id!).toPromise()
      .then((res?: IResCreatePaymentForCompetition) => {
        if (res?.status === 'REQUIRES_CONFIRMATION' && res.id) {
          const paymentId = res.id;
          // Show confirmation popup if the status requires confirmation
          this.openConfirmationPopup(paymentId);
          this.cd.detectChanges();
        } else {
          const redirectUrlFROMSERVER = 'https://hooks.stripe.com/redirect/authenticate/src_1NzFedBIMZTJKM1crS0CdOmW?client_secret=src_client_secret_WJAzupfTxhXSPGv4ag6Xyj9M&source_redirect_slug=test_YWNjdF8xSWlweHBCSU1aVEpLTTFjLF9PbXBKSWdjTFliVGhUY0Rna1dxYzFKMTQ3aldpV0lP0100JucGkk4t';
          this.resetAllThisData();
          // if (res?.redirectUrl) this.router.navigate([res?.redirectUrl]);
          if (res?.redirectUrl) window.open(redirectUrlFROMSERVER);
          else {
            // this.payS.currentLink$.next({ linkPayments: this.payS.getLinkPaymentsCamelCase(this.router.url) });
            this.router.navigate(['balances']);
          }
        }
      })
      .catch(() => {
      })
      .finally(() => this.endRequest());
  }

  onClosePopup(): void {
    this.showConfirmPopup = false;
  }

  changeVal(event: any, nameCtrl: 'amount'): void {
    if (nameCtrl == 'amount' && (event || event === 0)) {
      this.ctrl.processingFee?.patchValue(this.getFeeToString('processingFee', event));
      this.ctrl.transactionFee?.patchValue(this.getFeeToString('transactionFee', event));
      this.ctrl.amountPlusFee?.patchValue(this.getFeeToString('amountPlusFee', event));
    }
    this.cd.detectChanges();
  }

  getFeeToString(nameCtrl: 'processingFee' | 'transactionFee' | 'amountPlusFee', amount: string | number): string {
    // console.log('getFeeToString :', nameCtrl, amount)
    if (nameCtrl == 'processingFee') return '$' + this.otherS.getProcessingFee(+amount)?.toString()?.slice(0, 4);
    if (nameCtrl == 'transactionFee') return '$' + this.otherS.getTransactionFee(+amount)?.toString()?.slice(0, 4);
    if (nameCtrl == 'amountPlusFee') {
      // this.fundS.link.bank
      // return this.otherS.getAmountPlusFee(+amount, !this.fundS.link.bank)?.toString()?.slice(0, 4);
      return this.otherS.getAmountPlusFee(+amount, !this.fundS.link.bank)?.toString();
    }
    return '';
  }

  // === для библиотеки Plaid ===================
  plaidSubscribe(): void {
    this.plaidS.reset();
    this.plaidS.newBankMethodFromPlaid$.pipe(untilDestroyed(this)).subscribe((res: IPaymentMethod | null) => {
      if (res) this.getCurrentMethods(res); // !!! после закрытия попап окна плайда, нужно добавить в массив новый банковский паймент метод
    });
  }

  // === other ==================================
  findPayMethodById(idMethod: string): IPaymentMethod | undefined {
    // return this.methods?.find(el => el.id == idMethod);
    return this.payS.methodsSub$.getValue().find(el => el.id == idMethod);
  }

  // При переключении fundS.link меняется массив // или банки или карты.
  // addNewMethod передать, если нужно добавить в список новый payment метод
  getCurrentMethods(addNewMethod?: IPaymentMethod): void {
    if (addNewMethod) addNewMethod.isSelect = true;
    this.payS.getCurrentMethods(addNewMethod);
    const methods = this.payS.methodsSub$.getValue();
    if (methods.length) this.payMethodAndAmount = new ClassPayMethodAndAmount(methods[0], 0);
    this.showAddNewCard = false;
    if (addNewMethod) this.selectPayMethod(true, { ...addNewMethod, isSelect: true });
    window.scrollTo(0, 0);
    setTimeout(() => this.cd.detectChanges());
  }

  // если в пришел из пайментов и надо в дропдаун сразу поставить выбранный метод
  checkLocalStorage(): void {
    const { fromPaymentsPayMethodId, fromPaymentsPayMethodType } = this.fundS.checkLocalStorage();
    if (fromPaymentsPayMethodType) this.fundS.setLink({
      bank: fromPaymentsPayMethodType == 'ACH',
      card: fromPaymentsPayMethodType == 'CARD',
    });
    if (fromPaymentsPayMethodId) {
      const needChoicePayMethod = this.findPayMethodById(fromPaymentsPayMethodId); // нужно поставить этоот пайМетод в дропдаун
      if (needChoicePayMethod) {
        let allMethods = this.payS.allMethodsSub$.getValue() || [];
        allMethods = allMethods?.filter(el => el.id != fromPaymentsPayMethodId); // сначала убираю из массива
        this.payS.allMethodsSub$.next(allMethods);
        this.getCurrentMethods(needChoicePayMethod); // потом добавляю в начало списка
      }
    } else {
      this.getCurrentMethods();
    }
    setTimeout(() => this.cd.detectChanges());
  }

  // !!! сбросить все сохраненные данные в этом компоненте
  resetAllThisData(): void {
    this.plaidS.reset();
    this.showNewCard = false;
    this.payMethodAndAmount = new ClassPayMethodAndAmount();
    this.payS.methodsSub$.next([]);
    if (this.form) {
      this.otherS.ctrlReset([this.ctrl.amount, this.ctrl.amountPlusFee, this.ctrl.processingFee, this.ctrl.transactionFee], true);
      this.form.updateValueAndValidity();
      this.cd.detectChanges();
    }
    this.getCurrentMethods();
  }

  subscribeToCurrentLink(): void {
    this.fundS.link$.pipe(untilDestroyed(this)).subscribe((link) => {
      this.resetAllThisData();
      this.getCurrentMethods();
    });
  }

  deletePaymentMethodsCompetition(): void {
    if (!this.selectedMethod) return;
    // if (this.startRequest()) return;
    // this.mainS.deletePaymentMethodsCompetition(this.payS.competition?.id!, this.selectedMethod?.id!).toPromise()
    this.mainS.deletePaymentMethodsCompetition(this.ctrl.competitionId?.value!, this.selectedMethod?.id!).toPromise()
      .then((res?: null) => {
        // this.allMethods = this.allMethods?.filter(el => this.selectedMethod?.id !== el.id);
        let allMethods = this.payS.allMethodsSub$.getValue() || [];
        allMethods = allMethods?.filter(el => el.id != this.selectedMethod?.id); // сначала убираю из массива
        this.payS.allMethodsSub$.next(allMethods);
        this.resetAllThisData();
        this.checkLocalStorage();
      })
      .catch(() => {
      });
    // .finally(() => this.endRequest());
  }

  // === ADD NEW CARD ===========================
  addNewCard(): void {
    if (this.deviceS.isMobile) {
      this.showStripeForm = true;
    } else {
      this.showAddNewCard = true;
      this.cd.detectChanges();
      window.scrollTo(0, 9999);
    }
  }

  // === ADD NEW BANK ===========================
  getTokenAndLibraryPlaidCreateBank(): void {
    // this.plaidS.getTokenAndLibraryPlaidCreateBank(this.payS.competition?.id!);
    // this.plaidS.getTokenAndLibraryPlaidCreateBank(this.competitionId!);
    this.plaidS.getTokenAndLibraryPlaidCreateBank(this.ctrl.competitionId?.value!);
  }

  handleCancel(): void {
    this.showStripeForm = false;
    this.cd.detectChanges();
  }

  handlePaymentMethodCreated(paymentMethod: IPaymentMethod): void {
    // Hide the Stripe form
    this.showStripeForm = false;

    // Optionally, refresh the payment methods or perform any other necessary actions
    this.getCurrentMethods(paymentMethod);
  }

  // === OTHER ============================
  setCompetitionIdFromQueryParams(): void {
    this.route.queryParams
      .pipe(
        untilDestroyed(this),
        switchMap((res) => {
          if (res?.competitionId) this.ctrl.competitionId.patchValue(res.competitionId);
          else this.router.navigate(['balances']);
          return this.payS.getPaymentMethodsCompetition(this.ctrl.competitionId?.value);
        }),
      )
      .subscribe((res) => {
      });
  }
  goBackBalances(): void {
    this.router.navigate(['balances']);
  }
}
