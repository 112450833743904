import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { PopupService } from '@services/popup.service';
import { DateRangeDialogComponent } from '@app/dir_officials/page-planner/components/date-range-dialog/date-range-dialog.component';
import {
  arrPeriodsForCurrent,
  arrPeriodsForPast,
  customDateRange,
  IDatePeriod,
  TChoosePeriod,
} from '@components/__drop_inputs_matSelect/date-range/dateRange';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { ClassFilterDateRange } from '@components/filters/filters';
import { GetStateInputPipe } from '@pipes/get-state-input.pipe';
import { DateRangeService } from '@components/__drop_inputs_matSelect/date-range/date-range.service';
import { GetStylesPipe } from '@pipes/css/get-styles.pipe';
import { GetSvgArrowForDropdownPipe } from '@pipes/svg/get-svg-arrow-for-dropdown.pipe';
import { UtilsService } from '@services/utils.service';
import { defaultName_choosePeriod } from '../drop-date-range/drop-date-range.component';

@Component({
  selector: 'app-dateRange',
  standalone: true,
  imports: [CommonModule, GetWidthMatMenuPipe, MatMenuModule, SvgComponent, GetStateInputPipe, GetStylesPipe, GetSvgArrowForDropdownPipe],
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush, // !!! не ставить здесь
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'forOverlayPanelDateRange' },
    },
  ],
})
export class DateRangeComponent implements ControlValueAccessor {
  @Input() placeholder: string = 'View Date Range';
  @Input() showAlwaysPlaceholder: boolean = false; // если true => то не надо в дропдауне показывать выбраное значение. Тоесть показывать всегда placeholder
  @Input() typeView: 'svgCalendar' | 'drop' = 'drop'; // !!! 2 вида отображения - с иконкой календаря и в виде дропдауна
  // readonly arrPeriodsForCurrent = arrPeriodsForCurrent;
  // readonly arrPeriodsForPast = arrPeriodsForPast;
  get arrPeriodsForCurrent() {
    return [defaultName_choosePeriod, 'Today', 'Next 7 days', 'Next 2 weeks', 'Next month', 'Next 3 months', 'Next 12 months', customDateRange];
  }
  
  get arrPeriodsForPast() {
    return [defaultName_choosePeriod, 'Yesterday', 'Last 7 days', 'Last 2 weeks', 'Last month', 'Last 3 months', 'Last 12 months', customDateRange];
  }
  isOpenMenu = false;

  // === CSS STYLES =====================================================
  @Input() width: string | number = '';  // если надо ширину для дропдауна задать

  @Output() emit = new EventEmitter<ClassFilterDateRange>();

  constructor(
    private dateRangeS: DateRangeService,
    public elRef: ElementRef,
    public popupS: PopupService,
    public ngControl: NgControl,
    private cd: ChangeDetectorRef,
  ) {
    if (this.ngControl) this.ngControl.valueAccessor = this;
    // console.log('this.ngControl.valueAccessor :', this.ngControl.valueAccessor)
  }

  choosePeriod(period?: TChoosePeriod): void {
    // console.log('choosePeriod :', period)
    if (period === customDateRange) {
      this.openPopupDateRange();
      return;
    }
    setTimeout(() => {
      const filterDateRange: ClassFilterDateRange = { ...this.valueDateRange, period, fromTo_formatted: '' };
      // console.log('filterDateRange :', filterDateRange)
      this.valueDateRange = this.dateRangeS.checkDatePeriod(filterDateRange, 'DateRangeComponent choosePeriod()');
      this.cd.detectChanges();
    });
  }

  openPopupDateRange(): void {
    // console.log('openPopupDateRange :', this.value?.typePeriod, this.value)
    this.popupS.open(DateRangeDialogComponent, { width: '330px', currentLink: this.valueDateRange.typePeriod })
      .then((resDatePeriod: IDatePeriod | null) => {
        // console.log('openPopupDateRange RES:', resDatePeriod)
        if (resDatePeriod) {
          const filterDateRange: ClassFilterDateRange = new ClassFilterDateRange({
            ...this.valueDateRange,
            period: customDateRange,
            datePeriod: {},
          });
          const onlyOneDate: boolean = !resDatePeriod.from || !resDatePeriod.to; // выбрана только одна дата

          if (resDatePeriod.from) filterDateRange.datePeriod!.from = this.dateRangeS.getFormatDate(resDatePeriod.from as string, 'from');
          if (resDatePeriod.to) filterDateRange.datePeriod!.to = this.dateRangeS.getFormatDate(resDatePeriod.to as string, 'to');
          if (onlyOneDate) filterDateRange.datePeriod!.to = this.dateRangeS.getFormatDate(resDatePeriod.from as string, 'to');

          this.valueDateRange = {
            ...filterDateRange,
            // fromTo_formatted: this.dateRangeS.check_fromTo_formatted(resDatePeriod),
            fromTo_formatted: UtilsService.check_fromTo_formatted(resDatePeriod),
          } as ClassFilterDateRange;
        }
      });
  }

  // === GETTERS & SETTERS ========================================
  set valueDateRange(value: ClassFilterDateRange) {
    // console.log('SET VALUE :', value)
    // console.log('SET VALUE typePeriod :', value?.arrPeriods, value?.typePeriod)
    if (typeof value === 'undefined') return;
    // console.error(' ============================= EMIT :', value)
    this.emit.emit(value);
    this.onChange(value); // чтобы в родит.компонент передать == this.ngControl.control?.patchValue(value)
    this.onTouched();  // чтобы в родит.компонент передать
  }

  get valueDateRange(): ClassFilterDateRange {
    // console.log('GET VALUE :', this.ngControl.control?.value)
    // console.log('GET VALUE typePeriod :', this.ngControl.control?.value?.arrPeriods, this.ngControl.control?.value?.typePeriod)
    return this.ngControl.control?.value;
  }

  get errRequired(): boolean {
    return !!this.ngControl.control?.hasError('required') && !!this.ngControl.control?.touched;
  }

  get active(): boolean {
    return this.isOpenMenu;
  }

  get disabled(): boolean {
    return !!this.ngControl?.control?.disabled;
  }

  // === FOR ControlValueAccessor ===========================
  writeValue(responseDropDateRange: ClassFilterDateRange): void {
    // console.log('writeValue :', responseDropDateRange)
  }

  private onChange!: (value: ClassFilterDateRange) => void;
  private onTouched!: () => void;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  onPeriodItemClick(periodItem: string) {
    if (periodItem === "Custom Date Range" || this.valueDateRange?.period !== periodItem) {
      this.choosePeriod(periodItem as TChoosePeriod);
    }
  }
}
