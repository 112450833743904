import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClassTransfer, TransferModel } from '@models/transfer.model';
import { IOptionsForTransfers } from '@app/dir_group_assignor/payments/modelsForPayment';
import { OtherService } from '@services/other.service';
import { MeService } from '@services/me.service';
import { ForTestService } from '@classes/forTest';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { UtilsService } from '@services/utils.service';
import { CustomDatesService } from '@classes/CustomDates';

@Injectable({ providedIn: 'root' })
export class TransferApiService {
  headers = { 'Content-Type': 'application/json' };

  constructor(
    private http: HttpClient,
    private otherS: OtherService,
    private meS: MeService,
    private datesS: CustomDatesService,
    private forTestS: ForTestService,
  ) {
  }

  getOfficialTransfers(settings: ClassSettingsRequest): Observable<IResponse<ClassTransfer>> {
    return this.http.get<{ content: any[] }>(`/api/core/gamePayment/v1/transfer/official`, {
      headers: this.headers,
      params: { ...UtilsService.removeEmptyKeysFromObject({ ...settings, userId: this.meS.meId }) },
    })
      .pipe(
        catchError((err: any) => {
          // console.error(' ===== getOfficialTransfers err:', err)
          return of(err);
          //   return of({
          //     ...fakeSettingsRequest,
          //     content: [
          //       this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel,
          //       this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel,
          //     ],
          //   });
        }),
        // === for test ===
        // map((data) => {
        //   return {
        //     ...fakeSettingsRequest,
        //     content: [
        //       this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel,
        //       this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel, this.forTestS.fakeTransferModel,
        //     ],
        //   };
        // }),
        map((res) => {
          // console.log(' ===== getOfficialTransfers res:', res)
          return {
            ...res, content: res.content?.map((el: ClassTransfer) => {
              const updatedTransfer = {
                ...new ClassTransfer(el),
                // === andrei перенести в класс ==========================
                ageGenderLevel: this.otherS.getAgeGenderLevel(el, 'ClassTransfer'),
              };
              return updatedTransfer;
            }) || [],
          };
        }),
      );
  }

  getTransfers(settings: ClassSettingsRequest): Observable<IResponse<TransferModel> | null> { // TransferModel == ITransfer
    if (!settings.from) settings.from = this.datesS.getFormatDatePlusFewDays(this.datesS.format, -365); // today - 1 year
    if (!settings.to) settings.to = this.datesS.getFormatDatePlusFewDays(this.datesS.format, 0); // today

    const sendObj = UtilsService.removeEmptyKeysFromObject(settings);

    return this.http.get<IResponse<TransferModel>>(`/api/core/gamePayment/v1/transfer/all`, {
      headers: this.headers,
      params: { ...sendObj },
    })
      .pipe(
        map((data) => {
          const content: Array<TransferModel> = data?.content?.length ? data?.content.map((it) => new TransferModel(it)) : [];
          // const content = data?.content?.length ? data?.content.map((el) => new ClassTransfer(el)) : [];
          return { ...data, content };
        }),
        catchError(() => of(null)),
      );
  }

  // !!! FOR OFFICIAL ===========================================
  getTransfersOfficialSum(sendObj?: Pick<IOptionsForTransfers, 'transferType'>): Observable<number | null> {
    return this.http.get<number>(`/api/payments/v1/transfer/official/sum`, { params: sendObj })
      .pipe(
        map((res: number) => res ? res * 100 : 0),
        catchError((error: any) => of(null)),
      );
  }

  // !!! page payments/officialsFees // transferType: 'STRIPE' => Notch Pay // transferType: 'OFFLINE' => Offline // Officials Fees Paid == Notch Pay + Offline
  getTransfersSum(sendObj?: Pick<IOptionsForTransfers, 'transferType'>): Observable<number | null> {
    return this.http.get<number>(`/api/payments/v1/transfer/all/sum`, { params: UtilsService.removeEmptyKeysFromObject(sendObj!) })
      .pipe(
        map((res: number) => res ? res * 100 : 0),
        catchError((error: any) => of(null)),
      );
  }

}
