<div *ngIf='{
      cssClass: "o-matForm--"+({value:value,active:active,disabled:disabled,error:errRequired}|getStateInput)
  } as obj' class='wrapDropdown' [style]='{w:width}|getStyles'>
  <mat-form-field floatLabel='auto' class='o-matForm o-matForm--white {{obj.cssClass}} {{obj.cssClass}}--text6-blueDark'>

    <meSvg *ngIf='svgPrefix' matPrefix [svgName]='svgPrefix' [w]='widthSvgPrefix'></meSvg>
    <meSvg *ngIf='svgSuffix' matSuffix [svgName]='svgSuffix' [w]='widthSvgSuffix'></meSvg>

    <!--    (selectionChange)='changeSelect($event)'-->
    <mat-select #selectRef [class.enlargeReverse]='!!selectRef?.panelOpen'
                [(ngModel)]='value'
                [placeholder]='placeholder' [multiple]='multi' disableOptionCentering
                class='is-announcements'
                [disabled]='!!ngControl.control?.disabled'
                (openedChange)='checkClosedDrop($event)' [compareWith]='otherS.compareObj'>
      <mat-option [ngStyle]='(elRef|getWidthMatMenu:forTest)' class='hiddenMatOption'></mat-option>

      <ng-container *ngFor='let item of array; let idx=index; let first=first; let last=last'>
        <!--!!! пока не удалять class='{{listObjDropItem?.length ? (item.idCopyForDropFormCtrl ? "hiddenMatOption":"") : ""}}' -->
        <mat-option [value]='item'>
          <div class='matOptionWithSvg'>
            <!--            // === пока не удалять это === NO DELETE ===-->
            <!--            !!! === это для того чтобы выбраное значение показывать только если открыт дропдаун-->
            {{ selectRef?.panelOpen ? item.titleCase : (showAlwaysPlaceholder ? placeholder : item.titleCase) }}
            <!--            // === !!! а здесь в самом дропдауне вообще не показывается выбраное значение -->
            <!--            {{showAlwaysPlaceholder ? placeholder : item.titleCase}}-->
            <meSvg *ngIf='item.svgRightMatOption' [svgName]='item.svgRightMatOption'></meSvg>
            <ng-container *ngIf='item.count'>({{ item.count }})</ng-container>
          </div>
        </mat-option>
      </ng-container>

      <!--      // !!! пока не удалять. сейчас это не работает. позже сделаю-->
      <!--      <mat-option *ngIf='addNewItem' class='otherMatOption otherMatOption&#45;&#45;{{multi}}'>Other-->
      <!--        <input matInput [(ngModel)]='newItemValue' (ngModelChange)='newItemChange($event)'-->
      <!--               (click)='$event.stopPropagation();$event.preventDefault()'>-->
      <!--      </mat-option>-->
    </mat-select>
  </mat-form-field>

  <ng-content></ng-content>
</div>