import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
  MatDatepickerModule,
} from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUnavailableDate } from '@models/IUnavailableDate';
import { format } from 'date-fns';
import { IDataPopup } from '@services/popup.service';
import { CustomDatesService } from '@classes/CustomDates';
import { currentLink_current, currentLink_past } from '@classes/dictionary';
import { BtnComponent } from '@components/btn/btn.component';
import { IUnavailableDateDto } from '@models/IUnavailableDateDto';

@Component({
  selector: 'app-date-range-dialog',
  templateUrl: './date-range-dialog.component.html',
  styleUrls: ['./date-range-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  host: {
    class: 'g-flex-column',
  },
  imports: [
    MatDatepickerModule,
    BtnComponent,
  ],
})
export class DateRangeDialogComponent implements OnInit {
  selectedDateRange: DateRange<Date | null> = new DateRange(null, null);
  minDate: Date | null = null;
  maxDate: Date | null = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IDataPopup,
    public dialogRef: MatDialogRef<DateRangeDialogComponent, IUnavailableDateDto | null>,
    private customDatesS: CustomDatesService,
  ) {
    if (data.currentLink === currentLink_current) this.minDate = new Date(); // от текущей даты и в будущее
    if (data.currentLink === currentLink_past) this.maxDate = this.customDatesS.yesterdayDate; // из прошлого до вчерашней даты
  }

  ngOnInit() {
    if (this.data.unavailableDate) {
      this.selectedDateRange = new DateRange(
        new Date(this.data.unavailableDate.from!),
        new Date(this.data.unavailableDate.to!),
      );
    }
  }

  onSelectedChange(date: Date | null): void { // Thu Nov 09 2023 00:00:00 GMT+0700
    if (!date) return;
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(this.selectedDateRange.start, date);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }

  onSave() {
    let unavailableDate: IUnavailableDateDto = {
      from: this.selectedDateRange.start ? format(this.selectedDateRange.start, 'yyyy-MM-dd') : '',
      to: this.selectedDateRange.end ? format(this.selectedDateRange.end, 'yyyy-MM-dd') : '',
    };

    if (this.data.unavailableDate?.id) unavailableDate = { ...unavailableDate, id: this.data.unavailableDate?.id };

    this.dialogRef.close(unavailableDate as IUnavailableDateDto);
  }
}
