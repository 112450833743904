import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperClass } from '@classes/Helper-Classes';
import { MainService } from '@services/main.service';
import { ClassSettingsRequest, IResponse } from '@models/response-and-request';
import { PaymentService } from '@app/dir_group_assignor/payments/payment.service';
import { ClassCompetition, ClassCompetitionSeason } from '@app/dir_group_assignor/competitions/ClassCompetition';
import { BehaviorSubject, filter, forkJoin, from, map, mergeMap, Observable, switchMap, timer } from 'rxjs';
import { OtherService } from '@services/other.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DeviceService } from '@services/device.service';
import { FiltersService } from '@components/filters/filters.service';
import { ClassFilterDateRange, ClassFilterDrop, ClassFilterInput, IForClassForFilters } from '@components/filters/filters';
import { TLinkPaymentsItem } from '@app/dir_group_assignor/payments/modelsForPayment';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { WrapPageDirective } from '@directives/wrap-page.directive';
import { DropdownComponent } from '@components/__drop_inputs_matSelect/dropdown/dropdown.component';
import {
  FiltersSelectedIconComponent,
} from '@components/filters/components_additional/filters-selected-icon/filters-selected-icon.component';
import { FiltersComponent } from '@components/filters/filters/filters.component';
import { FiltersSelectedComponent } from '@components/filters/components_additional/filters-selected/filters-selected.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RoundBalancePipe } from '@app/dir_group_assignor/payments/pipes/round-balance.pipe';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { ProgressBarComponent } from '@components/progress-bar/progress-bar.component';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { BtnComponent } from '@components/btn/btn.component';
import { MatMenuModule } from '@angular/material/menu';
import { GetFirstLetterPipe } from '@pipes/get-first-letter.pipe';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MeTableService } from '@components/_table/me-table.service';
import { SettingsRequestService } from '@components/__settingsRequest/settings-request.service';
import { ApiCompetitionService } from '@app/dir_group_assignor/competitions/api-competition.service';
import { InfoListComponent } from '@components/__info-list/info-list/info-list.component';
import { ISwitcherBtn, SwitcherBtnComponent } from '@components/switcher-btn/switcher-btn.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BtnImportGamesComponent } from '@app/dir_group_assignor/games/components/btn-import-games/btn-import-games.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { LinkPageSearchFilterComponent } from '@components/__settingsRequest/link-page-search-filter/link-page-search-filter.component';
import { PaginationWithSettingsComponent } from '@components/__settingsRequest/pagination-with-settings/pagination-with-settings.component';
import { PhotosComponent } from '@components/__svg_img/photos/photos.component';
import { CeilTableDirective } from '@components/_table/directives/ceil-table.directive';
import { DataTableDirective } from '@components/_table/directives/data-table.directive';
import { ItemTableDirective } from '@components/_table/directives/item-table.directive';
import { GetTableEmptyPipe } from '@components/_table/pipes/get-table-empty.pipe';
import { TableBtnNumberComponent } from '@components/_table/table-btn-number/table-btn-number.component';
import { TableCeilActionsComponent } from '@components/_table/table-ceil-actions/table-ceil-actions.component';
import { TableCeilAssignorComponent } from '@components/_table/table-ceil-assignor/table-ceil-assignor.component';
import { TableCeilChxComponent } from '@components/_table/table-ceil-chx/table-ceil-chx.component';
import { TableCeilComponent } from '@components/_table/table-ceil/table-ceil.component';
import { TableEmptyComponent } from '@components/_table/table-empty/table-empty.component';
import { TableHeaderComponent } from '@components/_table/table-header/table-header.component';
import { TableInfiniteLoadingComponent } from '@components/_table/table-infinite-loading/table-infinite-loading.component';
import { AdminPermissionDirective } from '@directives/admin-permission.directive';
import { StopPropagationDirective } from '@directives/stop-propagation.directive';
import { AdminPermissionPipe } from '@pipes/adminPermission/admin-permission.pipe';
import { GetListGameForPossibleGameToCancelledPipe } from '@pipes/game_and_report/get-list-game-for-possible-game-to-cancelled.pipe';
import { GetListGameForPossibleGameToDeletePipe } from '@pipes/game_and_report/get-list-game-for-possible-game-to-delete.pipe';
import { GetMatTooltipCancelReasonPipe } from '@pipes/game_and_report/get-mat-tooltip-cancel-reason.pipe';
import { IsPossibleGameToCancelledPipe } from '@pipes/game_and_report/is-possible-game-to-cancelled.pipe';
import { IsPossibleGameToDeletePipe } from '@pipes/game_and_report/is-possible-game-to-delete.pipe';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { urlGames, urlGameInfo, urlCreateGame, urlGameImport } from '@app/app.module';
import { AssignService } from '@app/dir_group_assignor/assign/assign.service';
import { PopupCancelGameComponent } from '@app/dir_group_assignor/games/components/popup-cancel-game/popup-cancel-game.component';
import { GameService } from '@app/dir_group_assignor/games/game.service';
import { colorObj } from '@classes/CSS';
import { ForTestService } from '@classes/forTest';
import { PopupConfirmComponent } from '@components/__popup-windows/popup-confirm/popup-confirm.component';
import { PopupNewAnnouncementGeneralComponent } from '@components/__popup-windows/popup-new-announcement-general/popup-new-announcement-general.component';
import { IForClassForTable, ClassCeilTableHeader } from '@components/_table/meTable';
import { calculatePopupSizes } from '@models/other';
import { IOutputObjStyles } from '@pipes/css/get-styles.pipe';
import { MeService } from '@services/me.service';
import { PopupService, IDataPopup } from '@services/popup.service';
import { IUserGameTransferInfoDto } from './IUserGameTransferInfoDto';
import { GlobasConst } from '@shared/constants/global-const';
import { CurrencyCentsPipe } from "../../pipes/currency-cents.pipe";
import { StatusComponent } from '@components/status/status.component';
import { TransferType } from './TransferType';
import { urlPayments_assignorFees, urlPayments_paymentDetails } from '@app/url-const';

@UntilDestroy()
@Component({
  selector: 'app-payments-all-transactions',
  standalone: true,
  imports: [StatusComponent, InfoListComponent, CommonModule, BtnComponent, SwitcherBtnComponent,
    WrapPageDirective, CdkScrollable, DataTableDirective, DropdownComponent,
    FormsModule, FiltersSelectedIconComponent, FiltersComponent, FiltersSelectedComponent, TableHeaderComponent, TableCeilChxComponent,
    TableBtnNumberComponent, ItemTableDirective, TableCeilComponent, PhotosComponent, MatMenuModule, TableCeilActionsComponent,
    StopPropagationDirective, CeilTableDirective, TableEmptyComponent, TableInfiniteLoadingComponent, PaginationWithSettingsComponent,
    SvgComponent, BtnComponent, RouterLink, GetWidthMatMenuPipe, TableCeilAssignorComponent, InputCtrlComponent, ReactiveFormsModule, LinkPageSearchFilterComponent, MatTooltipModule, AdminPermissionDirective, AdminPermissionPipe, GetTableEmptyPipe, GetListGameForPossibleGameToCancelledPipe, GetListGameForPossibleGameToDeletePipe, IsPossibleGameToCancelledPipe, IsPossibleGameToDeletePipe, BtnImportGamesComponent, GetMatTooltipCancelReasonPipe, CurrencyCentsPipe, HeadTitleComponent],
  templateUrl: './payments-all-transactions.component.html',
  styleUrls: ['./payments-all-transactions.component.scss'],
  providers: [FiltersService, MeTableService, SettingsRequestService],
})
export class PaymentsAllTransactionsComponent extends HelperClass implements IForClassForFilters, IForClassForTable<IUserGameTransferInfoDto> {
  dataTable$ = new BehaviorSubject<IResponse<IUserGameTransferInfoDto>>({}); // for-table===
  arrContent$ = new BehaviorSubject<Array<IUserGameTransferInfoDto>>([]); // for-table===

  readonly widthPage = 1061;

  downloadDisabled = false;
  totalAssigningFees: number | undefined;
  availablePayout: number | undefined;


  countAllTransactions: number | undefined;
  constructor(
    public globalConst: GlobasConst,
    private apiCompetitionS: ApiCompetitionService,
    private mainS: MainService,
    public meS: MeService,
    public gameS: GameService,
    private otherS: OtherService,
    private popupS: PopupService,
    private route: ActivatedRoute,
    public router: Router,
    public deviceS: DeviceService,
    public filtersS: FiltersService, // for-filters===
    public meTableS: MeTableService<IUserGameTransferInfoDto>, // for-table===
    public settingsRequestS: SettingsRequestService, // for-settings===
    public forTestS: ForTestService,
    public assignS: AssignService,
    public cd: ChangeDetectorRef,
  ) {

    super(cd);

  }

  ngOnInit() {
    this.mainS.getAssignorFeesList({ params: { page: 1, size: 1 } }).subscribe(x => this.countAllTransactions = x.totalElements);
    const transactionId = this.route.snapshot.queryParams['gameId'];
    this.setAllData();
  }

  setAllData(): void {
    this.setDataForTable();
    this.setFilters();
    this.settingsRequestS.setAllData(this.isFirstLoadPageSub$);
    this.subscribeToSettings();
  }

  // === SETTINGS ===============================
  subscribeToSettings(): void {
    this.settingsRequestS.settings$.pipe(
      switchMap((res) => {
        this.getSeasons(res.competitions);
        return this.meTableS.getSubject_forAssignorFees();
      }),
      untilDestroyed(this),
    ).subscribe((res) => { });
  }


  setFilters(): void {
    const dateRange = new ClassFilterDateRange({ arrPeriods: ClassFilterDateRange.templatePreviosData });
    const competition = new ClassFilterDrop({ typeFilter: 'competitions' });
    const payoutFormat = new ClassFilterDrop({
      typeFilter: 'payoutFormat',
    });

    this.apiCompetitionS.getArrCompetition().toPromise()
      .then((res) => {
        const arrCompetitions = res?.content || [];
        const competitions_filterDrop = new ClassFilterDrop({
          typeFilter: 'competitions',
          arrayForDropdown: arrCompetitions || [],
          valueDrop: this.gameS.arrCompetition$.getValue()?.find(el => el.id === this.settingsRequestS.settings.competitions),
          disabled: false,
        });
        this.filtersS.updateAllFilters([competitions_filterDrop]);
      })
      .catch(err => {
      });
    const seasons = new ClassFilterDrop({ typeFilter: 'seasons', disabled: true });
    const search = new ClassFilterInput({ typeFilter: 'search' });
    this.filtersS.setFilters([search, dateRange, competition, payoutFormat, seasons], true, true);
  }

  savePrevCompetitionId: string | undefined = undefined;
  getSeasons(competitionId: string | undefined): void {
    if (competitionId == null || this.savePrevCompetitionId === competitionId)
      return;
    this.savePrevCompetitionId = competitionId;
    this.apiCompetitionS.methodCompetitionSeasons({ id: competitionId }, 'get').toPromise()
      .then((res?: Pick<ClassCompetition, 'seasons'>) => {
        const seasons = new ClassFilterDrop({
          typeFilter: 'seasons',
          disabled: !res?.seasons?.length,
          arrayForDropdown: res?.seasons || [],
        });
        this.filtersS.updateFilter('seasons', seasons);
      })
      .catch(err => {
      });
  }

  setDataForTable(): void {
    this.meTableS.dataTable$ = this.dataTable$;
    this.meTableS.arrContent$ = this.arrContent$;

    const arrayCeilHeader: Array<ClassCeilTableHeader> = [
      new ClassCeilTableHeader({ text: 'Game' }), // isChx: true
      new ClassCeilTableHeader({ text: 'Competition' }),
      new ClassCeilTableHeader({ text: 'Transaction Date' }),
      new ClassCeilTableHeader({ text: 'Amount' }),
      new ClassCeilTableHeader({ text: 'Status' }),
      new ClassCeilTableHeader({ text: '' }),
    ];

    const arrWidthCeilTable: Array<number> = [120, 371, 194, 120, 115, 135, this.meTableS.minWidthFor_sortByCeil];
    this.meTableS.setArrTypeSorting(['byGameNumber']);
    this.meTableS.setDataForTable(arrWidthCeilTable, arrayCeilHeader, this.widthPage, true);
  }

  goBack() {
    this.router.navigate(['/' + urlPayments_assignorFees]);
  }

  viewDetailsClicked(gameId: string, isButton: boolean) {
    if (this.deviceS.isMobile || isButton)
      this.router.navigate([urlPayments_paymentDetails], { queryParams: { gameId: gameId } });
  }

  downloadExcel() {
    const filterSettings = this.settingsRequestS.settings;
    const options = { params: filterSettings };

    const fileName = "notch all transactions.xlsx";
    /////////////!!!!!!!!!!URL
    this.mainS.exportToExcel("URL", options, fileName).subscribe();
  }
}


