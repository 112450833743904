import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeviceService } from '@services/device.service';
import { MeService } from '@services/me.service';
import { HelperClass } from '@classes/Helper-Classes';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SvgComponent } from '@components/__svg_img/svg/svg.component';
import { PhotoComponent } from '@components/__svg_img/photo/photo.component';
import { ClassUser, TUserRoleUpperCase, userRole_ADMIN, userRole_GROUP_ASSIGNOR, userRole_OFFICIAL } from '@models/user';
import { MatMenuModule } from '@angular/material/menu';
import { SvgAndTextComponent } from '@components/__svg_img/svg-and-text/svg-and-text.component';
import { GetWidthMatMenuPipe } from '@pipes/get-width-mat-menu.pipe';
import { MenuMeUserComponent } from '@app/shell/components/menu-me-user/menu-me-user.component';
import { TSvgName } from '@components/__svg_img/svg/forSvg';
import { MainService } from '@services/main.service';
import { GetUserNamePipe } from '@pipes/get-name.pipe';
import { rout_meProfile, rout_meProfileOfficial } from '@app/profile/profile';
import { DashboardService } from '@app/dir_group_assignor/dashboard/dashboard.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  TPath,
  urlAssign,
  urlBalances,
  urlCompetitions,
  urlCompetitionsList,
  urlDashboard,
  urlGames,
  urlMyGames,
  urlMyWallet,
  urlMyWallet_1,
  urlOfficials,
  urlOpenGames,
  urlPayments,
  urlReports,
  urlReporting
} from '@app/app.module';
import { UnsavedChangesService } from '@services/unsaved-changes.service';
import { RoutesService } from '@services/routes.service';
import { combineLatest } from 'rxjs';
import { urlPayments_assignorFees } from '@app/url-const';
import { EventEmitter } from '@angular/core';

interface IHeaderNavigation {
  text: string;
  url: string;
  path: TPath | null;
  svgRight?: TSvgName;
  // active?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'menuNavigation',
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, SvgComponent, PhotoComponent, MatMenuModule, SvgAndTextComponent, GetWidthMatMenuPipe, MenuMeUserComponent, GetUserNamePipe],
  templateUrl: './menu-navigation.component.html',
  styleUrls: ['./menu-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuNavigationComponent extends HelperClass implements OnInit {
  currentHeaderNavigation?: IHeaderNavigation;
  arrHeaderNavigation: Array<IHeaderNavigation> = [];
  arrHeaderAction: Array<IHeaderNavigation> = [];
  showMenu = false; // for mobile

  @Input() openMenuEvent: EventEmitter<void> | undefined;
  @Input() closeMenuEvent: EventEmitter<void> | undefined;
  protected readonly userRole_OFFICIAL = userRole_OFFICIAL;

  constructor(
    public dashboardS: DashboardService,
    public deviceS: DeviceService,
    public meS: MeService,
    public mainS: MainService,
    private routesS: RoutesService,
    private router: Router,
    private unsavedChangesS: UnsavedChangesService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.subscribeTo_device();
  }

  ngOnInit() {
    this.subscribeTo_me();
    this.subscribeTo_unsavedChanges_eventNavigation();
    this.openMenuEvent?.subscribe(()=>{
      this.showMenu = true;
      this.cd.detectChanges();
    })
    this.closeMenuEvent?.subscribe(()=>{
      this.showMenu = false;
      this.cd.detectChanges();
    })
  }

  subscribeTo_me(): void {
    this.meS.me$.pipe(untilDestroyed(this)).subscribe((res?: ClassUser) => {
      if (!res?.roleCurrent) return;
      this.getHeaderNavigation(res?.roleCurrent);
      this.getHeaderAction(res?.roleCurrent);
      this.checkCurrentHeaderNavigation();
    });
  }

  subscribeTo_unsavedChanges_eventNavigation(): void {
    combineLatest([this.unsavedChangesS.unsavedChanges$, this.routesS.eventNavigation$])
      .pipe(
        untilDestroyed(this),
      ).subscribe((res) => {
        this.checkCurrentHeaderNavigation();
      });
  }

  subscribeTo_device(): void {
    this.deviceS.listenResize().pipe(untilDestroyed(this)).subscribe((isMobile) => {
      if (!isMobile) this.showMenu = false;
    });
  }

  checkCurrentHeaderNavigation(): void {
    // console.log('path :', this.routesS.path)
    // console.log('urlWithoutQueryParams :', this.routesS.urlWithoutQueryParams)
    this.currentHeaderNavigation = this.arrHeaderNavigation.find(el => el.path === this.routesS.path);
    // console.log('currentHeaderNavigation :', this.currentHeaderNavigation)
    // if (this.currentHeaderNavigation) this.currentHeaderNavigation.active = true;
    this.cd.detectChanges();
  }

  getHeaderNavigation(userRole: TUserRoleUpperCase): void {
    if (userRole === userRole_OFFICIAL) {
      this.arrHeaderNavigation = [
        { text: 'Dashboard', url: `/${urlDashboard}`, path: urlDashboard },
        { text: 'My Games', url: `/${urlMyGames}`, path: urlMyGames }, //
        { text: 'Open Games', url: `/${urlOpenGames}`, path: urlOpenGames },
        { text: 'Post Game', url: `/${urlReports}`, path: urlReports },
        { text: 'My Wallet', url: urlMyWallet, path: urlMyWallet_1 },
        // { text: 'Messages', url: '/Messages' },
      ];
    } else if (userRole === userRole_GROUP_ASSIGNOR) {
      this.arrHeaderNavigation = [
        { text: 'Dashboard', url: `/${urlDashboard}`, path: urlDashboard },
        { text: 'Assign', url: `/${urlAssign}`, path: urlAssign },
        { text: 'Games', url: `/${urlGames}`, path: urlGames },
        { text: 'Post Game', url: `/${urlReports}`, path: urlReports },
        { text: 'Balances', url: `/${urlBalances}`, path: urlBalances },
        { text: 'Competitions', url: `/${urlCompetitionsList}`, path: urlCompetitions },
        { text: 'Officials', url: `/${urlOfficials}`, path: urlOfficials },
        // { text: 'Messages', url: '/messages' },
        // { text: 'Payments', url: `/${urlPayments}`, path: urlPayments },
        { text: 'Reports', url: `/${urlReporting}`, path: urlReporting }
      ];
    } else if (userRole === userRole_ADMIN) {
      this.arrHeaderNavigation = [
        //  Пока что страница дашборда не готова
        // { text: 'Dashboard', url: `/${urlDashboard}`, path: urlDashboard },
        { text: 'Games', url: `/${urlGames}`, path: urlGames },
      ];
    }
  }

  getHeaderAction(userRole: TUserRoleUpperCase): void {
    if (userRole === userRole_OFFICIAL) {
      this.arrHeaderAction = [
        // { text: 'Support', url: '/support' },
        { text: 'Settings', url: `${rout_meProfileOfficial}/info`, path: rout_meProfileOfficial },
        // { text: 'My Assignors', url: '' },
        // !!! NO DELETE { text: 'Switch to Admin', url: '', svgRight: 'arrow_switch&16' },
      ];
    } else if (userRole === userRole_GROUP_ASSIGNOR) {
      this.arrHeaderAction = [
        { text: 'Settings', url: `${rout_meProfile}/info`, path: rout_meProfile },
        // { text: 'Manage Plan', url: '', path: null },
        { text: 'Assignor Fees', url: urlPayments_assignorFees, path: null },
        // { text: 'Credit Usage', url: '', path: null },
        // !!! NO DELETE { text: 'Switch to Official', url: '', svgRight: 'arrow_switch_white&16' },
      ];
    } else if (userRole === userRole_ADMIN) {
      this.arrHeaderAction = [
        { text: 'Settings', url: `${rout_meProfile}/info`, path: rout_meProfile },
      ];
    }
  }

  changeLink(headerNavigation: IHeaderNavigation): void {
    // console.log('currentHeaderNavigation :', this.currentHeaderNavigation)
    // console.log('currentHeaderNavigation?.path :', this.currentHeaderNavigation?.path)
    // console.log('headerNavigation :', headerNavigation)
    // console.log('routesS.urlWithoutQueryParams :', this.routesS.urlWithoutQueryParams, '  headerNavigation.path :', headerNavigation.path, this.routesS.urlWithoutQueryParams === headerNavigation.path)
    // if (this.currentHeaderNavigation?.path === headerNavigation.path) return; // !!! нажал на текущую активную вкладку
    if (this.routesS.urlWithoutQueryParams === headerNavigation.path) return; // !!! нажал на текущую активную вкладку
    // this.arrHeaderNavigation.forEach(el => el.active = false);
    // headerNavigation.active = true;
    if (!this.unsavedChangesS.unsavedChanges) this.currentHeaderNavigation = headerNavigation;
    this.showMenu = false;
    this.router.navigate([headerNavigation.url]);
  }
}

// onClickLink(headerNavigation: IHeaderNavigation): void {
//   this.arrHeaderNavigation.forEach(el => el.active = false);
//   headerNavigation.active = true;
//   this.showMenu = false;
//   this.router.navigate([headerNavigation.url]);
// }
