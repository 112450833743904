import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BtnComponent } from '@components/btn/btn.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { GoogleSignUpComponent } from '@components/google/components/google-sign-up/google-sign-up.component';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { IResFromGoogleForSignup } from '@services/google-people.service';
import { ClassUser, TUserSignup } from '@models/user';
import { HelperClass } from '@classes/Helper-Classes';
import { AuthService } from '@app/auth/auth.service';
import { MainService } from '@services/main.service';
import { MeService } from '@services/me.service';
import { LocationService } from '@components/__blocks/location/location.service';
import { CustomValidators } from '@classes/CustomValidators';
import { OtherService } from '@services/other.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface IForm {
  email: FormControl<string>;
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
}

@UntilDestroy()
@Component({
  selector: 'emailPassStep',
  standalone: true,
  imports: [CommonModule, BtnComponent, FieldComponent, GoogleSignUpComponent, InputCtrlComponent, InvalidControlsPipe, ReactiveFormsModule, RouterLink],
  templateUrl: './email-pass-step.component.html',
  styleUrls: ['./email-pass-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailPassStepComponent extends HelperClass {
  // @Input() type!: TUserRoleUpperCase;
  form!: FormGroup<IForm>;
  viewPassword = false;

  // !!! если TUserSignup то вход регистрация без гугла. Если IResponseEmailPass то с помощью гугла
  @Output() emit = new EventEmitter<ClassUser>();

  constructor(
    public authS: AuthService,
    private formBuilder: UntypedFormBuilder,
    public mainS: MainService,
    private meS: MeService,
    public locationS: LocationService,
    // public routesS: RoutesService,
    public router: Router,
    public otherS: OtherService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.createForm();
    // this.subscribeToForm();
  }

  goToLogin(): void {
    this.router.navigate(['/login']);
  }

  signUp(): void {
    if (this.startRequest()) return;
    let sendObj: Pick<ClassUser, 'email' | 'password'> = { email: this.ctrl.email.value, password: this.ctrl.password.value };

    this.mainS.signUp(sendObj).toPromise()
      .then((res?: TUserSignup) => {
        if (res?.status == 'REGISTERED' && res?.id && res?.emailValidated && res?.phoneValidated) {
          this.mainS.confirmMe();
          this.endRequest();
          return;
        }
        this.emit.emit({ ...res, email: this.ctrl.email.value });
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  signupWithGoogle(resFromGoogleForSignup: IResFromGoogleForSignup): void {
    if (this.startRequest()) return;
    const sendObj: Pick<ClassUser, 'email'> = { email: resFromGoogleForSignup?.id_token! };
    // this.mainS.signupWithGoogle({ email: resFromGoogleForSignup?.id_token! }).toPromise() // !!! email == id_token from google
    this.mainS.signupWithGoogle(sendObj).toPromise() // !!! email == id_token from google
      .then((res?: TUserSignup) => { // TUserSignup = Pick<ClassUser, 'id' | 'emailValidated' | 'phoneValidated' | 'status'>
        const { fullName: preferredName, givenName: firstName, familyName: secondName, email } = resFromGoogleForSignup;
        const meUser: ClassUser = { ...res, firstName, secondName, preferredName, email, id: res?.id };
        this.meS.setMeUser(meUser, 'SignupComponent signupWithGoogle');
        if (res?.status == 'REGISTERED' && res?.id && res?.emailValidated && res?.phoneValidated) {
          this.mainS.confirmMe();
          this.endRequest();
          return;
        }
        this.ctrl.email.patchValue(email!);
        this.emit.emit({ ...meUser, emailValidated: true });
      })
      .catch((err) => {
      })
      .finally(() => {
        this.form.markAsUntouched();
        this.endRequest();
      });
  }

  // === FORM =================
  createForm(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.patternEmail]],
      password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
    }, { validators: CustomValidators.password });
  }

  get ctrl(): IForm {
    return this.form?.controls;
  }

  // subscribeToForm(): void {
  //   this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
  //     this.cd.detectChanges(); // !!! НЕ УДАЛЯТЬ. После signupWithGoogle не срабатывает пайп invalidControls
  //   });
  // }

}

