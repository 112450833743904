import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeService } from '@services/me.service';
import { DeviceService } from '@services/device.service';
import { MainService } from '@services/main.service';
import { Router } from '@angular/router';
import { BtnComponent } from '@components/btn/btn.component';
import { DropFormCtrlComponent } from '@components/__drop_inputs_matSelect/dropFormCtrl/dropFormCtrl.component';
import { FieldComponent } from '@components/__drop_inputs_matSelect/field/field.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { InputCtrlComponent } from '@components/__drop_inputs_matSelect/inputCtrl/inputCtrl.component';
import { ITimezone } from '@models/location';
import { CustomValidators } from '@classes/CustomValidators';
import { SelectTimezoneComponent } from '@components/__drop_inputs_matSelect/select-timezone/select-timezone.component';
import { HeadTitleComponent } from '@components/head-title/head-title.component';
import { BtnWrapComponent } from '@components/btn-wrap/btn-wrap.component';
import { ContactItemComponent } from '@app/profile/additional_components/contact-item/contact-item.component';
import { FieldRowComponent } from '@components/__drop_inputs_matSelect/field-row/field-row.component';
import { HelperClass } from '@classes/Helper-Classes';
import { InvalidControlsPipe } from '@pipes/invalid-controls.pipe';
import { arrNameCtrlForPassword, OtherService } from '@services/other.service';
import { ProfileService } from '@app/profile/profile.service';
import {
  TitleAndNavigationForProfileComponent,
} from '@app/profile/additional_components/title-and-navigation-for-profile/title-and-navigation-for-profile.component';
import { rout_meProfile, rout_meProfileOfficial, TypePageProfile } from '@app/profile/profile';
import { UntilDestroy } from '@ngneat/until-destroy';

interface IMeProfileAccountForm {
  password: FormControl<string>;
  confirmPassword: FormControl<string>;
  currentPassword: FormControl<string>;
  // timezone: FormControl<ITimezone>;
}

@UntilDestroy()
@Component({
  selector: 'app-me-profile-account',
  standalone: true,
  imports: [CommonModule, BtnComponent, DropFormCtrlComponent, FieldComponent, FormsModule, InputCtrlComponent, ReactiveFormsModule,
    SelectTimezoneComponent, HeadTitleComponent, BtnWrapComponent, ContactItemComponent, FieldRowComponent, InvalidControlsPipe, TitleAndNavigationForProfileComponent],
  templateUrl: './me-profile-account.component.html',
  styleUrls: ['./me-profile-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeProfileAccountComponent extends HelperClass {
  typePageProfile!: TypePageProfile;

  form!: FormGroup<IMeProfileAccountForm>;
  arrNameCtrlForPassword = arrNameCtrlForPassword;
  viewPassword = false;

  // ctrl_timezone = new FormControl<ITimezone | null>(null)
  // timezone$ = new BehaviorSubject<ITimezone | null>(null)
  timezone: ITimezone | null = null;

  constructor(
    public meS: MeService,
    public deviceS: DeviceService,
    public mainS: MainService,
    public otherS: OtherService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public profileS: ProfileService,
    public cd: ChangeDetectorRef,
  ) {
    super(cd);
    this.checkCurrentRout();
    this.createForm();
  }

  checkCurrentRout(): void {
    if (this.router.url?.includes(rout_meProfile)) this.typePageProfile = rout_meProfile;
    if (this.router.url?.includes(rout_meProfileOfficial)) this.typePageProfile = rout_meProfileOfficial;
  }

  changePass(): void {
    if (this.startRequest()) return;
    const sendObj: { currentPass: string; newPass: string } = {
      currentPass: this.ctrl.currentPassword?.value,
      newPass: this.ctrl.confirmPassword?.value,
    };
    this.mainS.changePass(sendObj).toPromise()
      .then((res) => {
      })
      .catch((err) => {
      })
      .finally(() => this.endRequest());
  }

  // === FORM ==============
  private createForm(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      confirmPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      currentPassword: ['', [Validators.required, Validators.minLength(7), CustomValidators.patternPassword]],
      // timezone: [''],
    }, { validators: CustomValidators.password });
  }

  get ctrl(): IMeProfileAccountForm {
    return this.form?.controls;
  }

  // === TIMEZONE ===================================
  updateTimezone(): void {
    // console.log('timezone :', this.timezone);
  }

  // changeTimezone(event: ITimezone): void {
  //   this.timezone = event
  // }
}
